<template>
  <v-card v-if="tutorialGroup">
    <v-card-title class="py-3">
      <v-avatar
        size="40"
        color="white"
        class="mr-4"
      >
        <v-icon
          size="20"
          color="primary"
        >
          {{ icons.mdiPencil }}
        </v-icon>
      </v-avatar>
      {{ tutorialGroup.title }}
    </v-card-title>
    <v-card-text class="py-4">
      <draggable
        v-if="tutorialGroup.tutorials.length > 0"
        v-model="tutorialGroup.tutorials"
        ghost-class="ghost"
        v-bind="dragOptions"
        @change="updateList"
      >
        <transition-group>
          <div
            v-for="item in tutorialGroup.tutorials"
            :key="item.id"
            class="px-0 pt-2 d-flex hover-pointer"
            :class="{ 'tutorial-active' : tutorial.id == item.id }"
            @click="$emit('deselect-video'); fetchTutorial(item.id)"
          >
            <v-icon
              size="14"
              class="mt-1 mx-2"
            >
              {{ icons.mdiCircleOutline }}
            </v-icon>
            <p class="mb-3 font-weight-medium">
              {{ tutorial.id && (tutorial.id == item.id) ? tutorial.title : item.title }}
            </p>
          </div>
        </transition-group>
      </draggable>
      <div
        v-if="tutorial.title && !tutorial.id"
        class="px-0 pt-2 d-flex"
      >
        <v-icon
          size="14"
          class="mt-1 mx-2"
        >
          {{ icons.mdiCircleOutline }}
        </v-icon>
        <p class="mb-3 font-weight-medium">
          {{ tutorial.title }}
        </p>
      </div>
      <div
        v-if="tutorialGroup.tutorials.length > 1"
        class="caption text-center mt-3"
      >
        Drag and drop to rearrange sections
      </div>
      <div v-if="(tutorialGroup.tutorials.length == 0) && !tutorial.title">
        <h3 class="mt-3 mb-1">
          No tutorials yet!
        </h3>
        <p class="caption">
          Tutorial list will appear after saving new page content on the right
        </p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  computed,
} from '@vue/composition-api'
import draggable from 'vuedraggable'

export default {
  components: {
    draggable,
  },
  props: {
    tutorialGroup: {
      type: Object,
      default: null,
    },
    tutorial: {
      type: Object,
      default: null,
    },
    icons: {
      type: Object,
      default: null,
    },
    fetchTutorial: {
      type: Function,
      default: () => 1,
    },
    updateList: {
      type: Function,
      default: () => 1,
    },
  },
  setup() {
    const dragOptions = computed(() => (
      {
        animation: 100,
        group: 'tutorial-list',
        disabled: false,
      }
    ))

    return {
      // Computed
      dragOptions,
    }
  },
}
</script>

<style lang="scss">
.tutorial-active {
  background-color: #DAF3EC;
  border-radius: 5px;
}
.theme--light {
  .tutorial-active p,
  .tutorial-active span {
    color: var(--v-primary-base);
  }
}
.theme--dark {
  .tutorial-active {
    background-color: #474461;
  }
}
</style>
