import {
  mdiFaceAgent,
  mdiViewModule,
  mdiMessageBulleted,
  mdiMagnifyPlusOutline,
} from '@mdi/js'

export default [
  {
    title: 'Customers',
    icon: mdiFaceAgent,
    to: { name: 'customer' },
    resource: 'Admin',
    action: 'read',
  },
  {
    title: 'Modules',
    icon: mdiViewModule,
    to: { name: 'module' },
    resource: 'Admin',
    action: 'read',
  },
  {
    title: 'Release Notes',
    icon: mdiMessageBulleted,
    to: { name: 'release-note' },
    resource: 'Admin',
    action: 'read',
  },
  {
    title: 'Tutorials',
    icon: mdiMagnifyPlusOutline,
    to: { name: 'tutorial-group' },
    resource: 'Admin',
    action: 'read',
  },
]
