var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-7"},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex align-center"},[_c('v-row',[_c('v-col',{staticClass:"my-auto text-no-wrap text-subtitle-1 text-sm-h6"},[_vm._v(" Members List ("+_vm._s(_vm.customer.members_count)+"/"+_vm._s(_vm.customer.members_quota)+") ")]),_c('v-col',{attrs:{"align":"end"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.isTopUpMemberDialogVisible = true; _vm.customer.original_quota = _vm.customer.members_quota}}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_c('span',{staticClass:"d-none d-sm-block"},[_vm._v("Top up member quota")])],1)],1)],1)],1),_c('v-data-table',{staticClass:"d-none d-sm-block",attrs:{"headers":_vm.tableColumnHeaders,"items":_vm.customer.members,"mobile-breakpoint":"0","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.member",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"v-avatar-light-bg primary--text me-3",attrs:{"size":"33","color":"primary"}},[_vm._v(" "+_vm._s(item.initials)+" ")]),_c('div',{staticClass:"text-no-wrap"},[_c('p',{staticClass:"font-weight-medium text--primary mb-n1"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('span',{staticClass:"text-xs text--disabled"},[_vm._v(_vm._s(item.email))])])],1)]}},{key:"item.joined_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.joined_at)+" ")])]}}],null,true)}),_c('v-data-table',{staticClass:"d-block d-sm-none",attrs:{"headers":_vm.tableSmColumnHeaders,"items":_vm.customer.members,"expanded":_vm.expanded,"single-expand":"","show-expand":"","mobile-breakpoint":"0","hide-default-footer":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.member",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"v-avatar-light-bg primary--text me-3",attrs:{"size":"33","color":"primary"}},[_vm._v(" "+_vm._s(item.initials)+" ")]),_c('div',{staticClass:"text-no-wrap"},[_c('p',{staticClass:"font-weight-medium text--primary mb-n1"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('span',{staticClass:"text-xs text--disabled"},[_vm._v(_vm._s(item.email))])])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"table-expand",attrs:{"colspan":headers.length}},[_c('p',{staticClass:"mt-2"},[_vm._v(" Joined at: "+_vm._s(item.joined_at)+" ")])])]}}],null,true)})],1),_c('v-dialog',{attrs:{"width":"500","persistent":""},model:{value:(_vm.isTopUpMemberDialogVisible),callback:function ($$v) {_vm.isTopUpMemberDialogVisible=$$v},expression:"isTopUpMemberDialogVisible"}},[(_vm.customer.members)?_c('v-card',[_c('v-card-title',{staticClass:"d-flex align-center mv-4"},[_vm._v(" Top Up Member Quota "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.isTopUpMemberDialogVisible = false; _vm.customer.members_quota = _vm.customer.original_quota}}},[_c('v-icon',{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1)],1),_c('v-card-text',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"mb-0"},[_vm._v(" Change members quota. Customer will be paying "),_c('br'),_c('span',{staticClass:"primary--text"},[_vm._v("$"+_vm._s(_vm.customer.members_quota * 6)+".00")]),_vm._v(" starting on the next billing cycle. ")]),_c('v-spacer'),_c('v-form',{ref:"membersQuotaForm"},[_c('v-text-field',{staticClass:"quota-input",attrs:{"outlined":"","dense":"","reverse":"","min":_vm.customer.members_count,"type":"number","hide-details":"auto","prefix":"users"},model:{value:(_vm.customer.members_quota),callback:function ($$v) {_vm.$set(_vm.customer, "members_quota", $$v)},expression:"customer.members_quota"}})],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"mt-3",attrs:{"color":"primary","block":"","loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.updateCustomer}},[_vm._v(" Change members quota ")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }