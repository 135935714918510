import { canNavigate } from '@/plugins/acl/routeProtection'
import Vue from 'vue'
import VueRouter from 'vue-router'
import pages from './pages'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: to => {
      const authHeaders = localStorage.getItem('authHeaders')
      const isLoggedIn = authHeaders && localStorage.getItem('userData')

      if (isLoggedIn) return { name: 'customer' }

      return { name: 'auth-login', query: to.query }
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/customer',
    name: 'customer',
    component: () => import('@/views/customer/CustomerList.vue'),
    meta: {
      layout: 'content',
      resource: 'Admin',
    },
  },
  {
    path: '/customer/:id?',
    name: 'customer-view',
    component: () => import('@/views/customer/CustomerView.vue'),
    meta: {
      layout: 'content',
      resource: 'Admin',
    },
  },
  {
    path: '/module',
    name: 'module',
    component: () => import('@/views/module/ModuleList.vue'),
    meta: {
      layout: 'content',
      resource: 'Admin',
    },
  },
  {
    path: '/release_note',
    name: 'release-note',
    component: () => import('@/views/release_note/ReleaseNote.vue'),
    meta: {
      layout: 'content',
      resource: 'Admin',
    },
  },
  {
    path: '/tutorial_group',
    name: 'tutorial-group',
    component: () => import('@/views/tutorial/TutorialGroup.vue'),
    meta: {
      layout: 'content',
      resource: 'Admin',
    },
  },
  {
    path: '/tutorial_group/:id?',
    name: 'tutorial-edit',
    component: () => import('@/views/tutorial/TutorialEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'Admin',
    },
  },
  ...pages,
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, _, next) => {
  const authHeaders = localStorage.getItem('authHeaders')
  const isLoggedIn = authHeaders && localStorage.getItem('userData')

  if (!canNavigate(to)) {
    if (!isLoggedIn) return next({ name: 'auth-login', query: { marketplace: to.query.marketplace } })

    return next({ name: 'misc-not-authorized' })
  }
  if (to.meta.redirectIfLoggedIn && !!isLoggedIn) next('/')

  return next()
})

export default router
