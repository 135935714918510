var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pt-10"},[_c('v-card-title',{staticClass:"justify-center flex-column"},[_c('v-avatar',{staticClass:"v-avatar-light-bg primary--text mb-4",attrs:{"color":"primary","size":"120","rounded":""}},[_c('v-img',{attrs:{"src":_vm.customer.logo_url},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-sheet',[_c('v-img',{attrs:{"src":require("@/assets/images/logo.png")}})],1)]},proxy:true}])})],1),_c('span',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.customer.display_name))])],1),_c('v-card-text',{staticClass:"mt-5"},[_c('div',{staticClass:"d-flex"},[_c('v-card-title',{staticClass:"pl-0 pr-2"},[_vm._v(" Billing Info ")]),_c('v-menu',{attrs:{"bottom":"","right":"","transition":"scale-transition","origin":"top left"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({class:("v-chip-light-bg my-auto " + _vm.statusTextColor + "--text"),attrs:{"label":"","color":"primary"}},on),[_vm._v(" "+_vm._s(_vm.billing.status)+" ")])]}}])},[_c('v-card',{staticClass:"px-5 pt-1"},[_c('v-radio-group',{model:{value:(_vm.billing.status),callback:function ($$v) {_vm.$set(_vm.billing, "status", $$v)},expression:"billing.status"}},_vm._l((_vm.statusOptions),function(n){return _c('v-radio',{key:n,attrs:{"label":n,"value":n,"color":_vm.statusTextColor}})}),1)],1)],1)],1),_c('v-row',{staticClass:"my-5 primary--text",attrs:{"align":"end","justify":"center"}},[_c('div',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v(" $ ")]),_c('div',{staticClass:"display-2 font-weight-bold"},[_vm._v(" "+_vm._s(Math.round(_vm.billingTotal))+" ")]),_c('div',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v(" .00/"+_vm._s(_vm.billing.payment_period === 'Monthly' ? 'month' : 'year')+" ")])]),_c('div',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","eager":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"header-inputs flex-grow-0 mb-3",attrs:{"append-icon":_vm.icons.mdiCalendar,"readonly":"","outlined":"","dense":"","label":"Start Date","hide-details":"auto"},model:{value:(_vm.startDateFormatted),callback:function ($$v) {_vm.startDateFormatted=$$v},expression:"startDateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.isStartDateMenuOpen),callback:function ($$v) {_vm.isStartDateMenuOpen=$$v},expression:"isStartDateMenuOpen"}},[_c('v-date-picker',{attrs:{"color":"primary","first-day-of-week":1},on:{"input":function($event){_vm.isStartDateMenuOpen = false; _vm.endDateRef.validate(); _vm.paymentPeriodRef.validate()}},model:{value:(_vm.billing.start_date),callback:function ($$v) {_vm.$set(_vm.billing, "start_date", $$v)},expression:"billing.start_date"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","eager":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({ref:"endDateRef",staticClass:"header-inputs flex-grow-0 mb-3",attrs:{"append-icon":_vm.icons.mdiCalendar,"readonly":"","outlined":"","dense":"","label":"End Date","hide-details":"auto","rules":_vm.endDateRules},model:{value:(_vm.endDateFormatted),callback:function ($$v) {_vm.endDateFormatted=$$v},expression:"endDateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.isEndDateMenuOpen),callback:function ($$v) {_vm.isEndDateMenuOpen=$$v},expression:"isEndDateMenuOpen"}},[_c('v-date-picker',{attrs:{"color":"primary","first-day-of-week":1},on:{"input":function($event){_vm.isEndDateMenuOpen = false; _vm.paymentPeriodRef.validate()}},model:{value:(_vm.billing.end_date),callback:function ($$v) {_vm.$set(_vm.billing, "end_date", $$v)},expression:"billing.end_date"}})],1),_c('v-text-field',{staticClass:"mb-3",attrs:{"outlined":"","dense":"","label":"Discount","prefix":"BND$","type":"number","rules":_vm.discountRules,"hide-details":"auto"},on:{"input":_vm.calculateTotal},model:{value:(_vm.billing.discount),callback:function ($$v) {_vm.$set(_vm.billing, "discount", $$v)},expression:"billing.discount"}}),_c('v-select',{ref:"paymentPeriodRef",staticClass:"mb-3",attrs:{"items":_vm.paymentPeriodOptions,"outlined":"","dense":"","hide-details":"auto","label":"Payment Period","rules":_vm.paymentPeriodRules},on:{"input":_vm.calculateTotal},model:{value:(_vm.billing.payment_period),callback:function ($$v) {_vm.$set(_vm.billing, "payment_period", $$v)},expression:"billing.payment_period"}}),_c('v-textarea',{staticClass:"mb-3",attrs:{"outlined":"","rows":"3","hide-details":"auto","label":"Notes"},model:{value:(_vm.billing.notes),callback:function ($$v) {_vm.$set(_vm.billing, "notes", $$v)},expression:"billing.notes"}}),_c('v-btn',{staticClass:"mt-3",attrs:{"color":"primary","width":"100%","depressed":"","loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.updateBilling}},[_vm._v(" Save ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }