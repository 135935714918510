import axios from 'axios'
import Vue from 'vue'
import encryptionService from '@/services/encryption.service'

const baseURL = process.env.VUE_APP_API_URL

const axiosIns = axios.create({
  baseURL,
  timeout: 5000,
  headers: { 'Access-Control-Allow-Origin': '*' },
})

// eslint-disable no-param-reassign
axiosIns.interceptors.request.use(
  config => {
    const encryptedAuthHeaders = localStorage.getItem('authHeaders')

    if (encryptedAuthHeaders) {
      const authHeaders = JSON.parse(encryptionService.decrypt(encryptedAuthHeaders))
      config.headers[config.method] = {
        'access-token': authHeaders['access-token'],
        client: authHeaders.client,
        uid: authHeaders.uid,
        expiry: authHeaders.expiry,
        'token-type': authHeaders['token-type'],
      }
    }

    return config
  },
  error => Promise.reject(error),
)

axiosIns.interceptors.response.use(
  response => {
    if (response.headers['access-token']) {
      const authHeaders = {
        'access-token': response.headers['access-token'],
        client: response.headers.client,
        uid: response.headers.uid,
        expiry: response.headers.expiry,
        'token-type': response.headers['token-type'],
      }
      const encryptedAuthHeaders = encryptionService.encrypt(JSON.stringify(authHeaders))
      localStorage.setItem('authHeaders', encryptedAuthHeaders)
    }

    return response
  },
  error => Promise.reject(error),
)

Vue.prototype.$http = axiosIns

export {
  axiosIns as axios,
  baseURL,
}
