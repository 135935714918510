<template>
  <div class="mb-7">
    <v-card>
      <v-card-title class="d-flex align-center">
        <v-row>
          <v-col class="my-auto text-no-wrap text-subtitle-1 text-sm-h6">
            Members List ({{ customer.members_count }}/{{ customer.members_quota }})
          </v-col>

          <v-col align="end">
            <v-btn
              color="primary"
              @click="isTopUpMemberDialogVisible = true; customer.original_quota = customer.members_quota"
            >
              <v-icon
                size="18"
                class="me-1"
              >
                {{ icons.mdiPlus }}
              </v-icon>
              <span class="d-none d-sm-block">Top up member quota</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table
        :headers="tableColumnHeaders"
        :items="customer.members"
        mobile-breakpoint="0"
        hide-default-footer
        class="d-none d-sm-block"
      >
        <template #[`item.member`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              size="33"
              color="primary"
              class="v-avatar-light-bg primary--text me-3"
            >
              {{ item.initials }}
            </v-avatar>
            <div class="text-no-wrap">
              <p class="font-weight-medium text--primary mb-n1">
                {{ item.name }}
              </p>
              <span class="text-xs text--disabled">{{ item.email }}</span>
            </div>
          </div>
        </template>
        <template #[`item.joined_at`]="{item}">
          <div class="text-no-wrap">
            {{ item.joined_at }}
          </div>
        </template>
      </v-data-table>

      <!-- table view for mobile -->
      <v-data-table
        :headers="tableSmColumnHeaders"
        :items="customer.members"
        :expanded.sync="expanded"
        single-expand
        show-expand
        mobile-breakpoint="0"
        hide-default-footer
        class="d-block d-sm-none"
      >
        <template #[`item.member`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              size="33"
              color="primary"
              class="v-avatar-light-bg primary--text me-3"
            >
              {{ item.initials }}
            </v-avatar>
            <div class="text-no-wrap">
              <p class="font-weight-medium text--primary mb-n1">
                {{ item.name }}
              </p>
              <span class="text-xs text--disabled">{{ item.email }}</span>
            </div>
          </div>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td
            class="table-expand"
            :colspan="headers.length"
          >
            <p class="mt-2">
              Joined at: {{ item.joined_at }}
            </p>
          </td>
        </template>
      </v-data-table>
    </v-card>

    <!-- Top up member -->
    <v-dialog
      v-model="isTopUpMemberDialogVisible"
      width="500"
      persistent
    >
      <v-card v-if="customer.members">
        <v-card-title class="d-flex align-center mv-4">
          Top Up Member Quota
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isTopUpMemberDialogVisible = false; customer.members_quota = customer.original_quota"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="d-flex align-center">
          <p class="mb-0">
            Change members quota. Customer will be paying <br>
            <span class="primary--text">${{ customer.members_quota * 6 }}.00</span> starting on the next billing cycle.
          </p>
          <v-spacer></v-spacer>
          <v-form ref="membersQuotaForm">
            <v-text-field
              v-model="customer.members_quota"
              outlined
              dense
              reverse
              :min="customer.members_count"
              type="number"
              hide-details="auto"
              prefix="users"
              class="quota-input"
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="primary"
            block
            :loading="loading"
            :disabled="loading"
            class="mt-3"
            @click="updateCustomer"
          >
            Change members quota
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  ref,
  inject,
} from '@vue/composition-api'
import {
  mdiPlus,
  mdiClose,
} from '@mdi/js'
import { useRouter } from '@core/utils'

export default {
  props: {
    customer: {
      type: Object,
      default: null,
    },
    fetchCustomer: {
      type: Function,
      default: null,
    },
    fetchBilling: {
      type: Function,
      default: null,
    },
  },
  setup(props) {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const { route } = useRouter()

    // Properties
    const isTopUpMemberDialogVisible = ref(false)
    const loading = ref(false)
    const expanded = ref([])
    const tableColumnHeaders = [
      { text: 'MEMBER', value: 'member', sortable: false },
      { text: 'ROLE', value: 'role', sortable: false },
      {
        text: 'JOINED AT',
        value: 'joined_at',
        sortable: false,
        align: 'center',
      },
    ]
    const tableSmColumnHeaders = [
      { text: 'MEMBER', value: 'member', sortable: false },
      { text: 'ROLE', value: 'role', sortable: false },
      { text: '', value: 'data-table-expand' },
    ]

    // Methods
    const updateCustomer = () => {
      loading.value = true
      store
        .dispatch('customerStore/updateCustomer', { id: route.value.params.id, members_quota: props.customer.members_quota })
        .then(response => {
          const { message } = response.data

          loading.value = false
          isTopUpMemberDialogVisible.value = false
          props.fetchCustomer(route.value.params.id)
          props.fetchBilling(route.value.params.id)
          snackbarService.success(message)
        })
        .catch(error => {
          const { message } = error.response.data

          loading.value = false
          snackbarService.error(message)
        })
    }

    return {
      // Properties
      isTopUpMemberDialogVisible,
      loading,
      expanded,
      tableColumnHeaders,
      tableSmColumnHeaders,

      // Icons
      icons: {
        mdiPlus,
        mdiClose,
      },

      // Methods
      updateCustomer,
    }
  },
}
</script>
