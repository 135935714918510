import { axios } from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchModules(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/admin/subscription_types', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchModule(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/admin/subscription_types/${params.id}`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createModule(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/admin/subscription_types', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateModule(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/admin/subscription_types/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteModule(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/admin/subscription_types/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
