export default {
  truncate(payload, length) {
    const string = `${payload.slice(0, length)}...`

    return payload.length > length ? string : payload
  },
  formatDate(date, time = '') {
    if (!date) return null
    const [year, month, day] = date.split('-')
    if (time === '') return `${day}/${month}/${year}`
    const datetime = time === 'start' ? '00:00' : '23:59'

    return `${day}/${month}/${year} ${datetime}`
  },
}
