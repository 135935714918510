<template>
  <div>
    <v-card>
      <v-card-title>
        Available Modules
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            v-for="plan in plansList"
            :key="plan.id"
            cols="12"
            md="6"
            lg="4"
            align-self="center"
          >
            <v-card
              outlined
              :class="{ 'primary-border': plan.subscribed_by_customer }"
            >
              <v-card-text class="d-flex pa-3">
                <v-icon
                  size="30"
                  class="me-3 my-auto"
                  :color="plan.subscribed_by_customer ? 'primary' : 'secondary'"
                >
                  {{ moduleIcon(plan) }}
                </v-icon>
                <div>
                  <h5>{{ plan.name }}</h5>
                  <p class="mb-0">
                    ${{ plan.payment }}/month
                  </p>
                </div>
                <v-spacer></v-spacer>
                <v-menu
                  bottom
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      v-if="!plan.subscribed_by_customer"
                      @click="createSubscription(plan)"
                    >
                      <v-list-item-title>
                        <v-icon
                          size="20"
                          class="me-2"
                        >
                          {{ icons.mdiWrenchOutline }}
                        </v-icon>
                        <span>Install</span>
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      v-else
                      @click="cancelledSubscription = plan; isCancelSubscriptionDialogOpen = true"
                    >
                      <v-list-item-title>
                        <v-icon
                          size="20"
                          class="me-2"
                        >
                          {{ icons.mdiDeleteOutline }}
                        </v-icon>
                        <span>Uninstall</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>

      <v-dialog
        v-model="isCancelSubscriptionDialogOpen"
        max-width="500"
        persistent
      >
        <v-card>
          <v-card-title
            v-if="cancelledSubscription"
            class="d-flex align-center mv-4"
          >
            Uninstall Module?
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              @click="isCancelSubscriptionDialogOpen = false; cancelledSubscription = null"
            >
              <v-icon size="22">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <div class="text-center mb-4">
              <template v-if="cancelledSubscription">
                <v-chip
                  label
                  color="primary"
                  class="v-chip-light-bg primary--text font-weight-semibold"
                >
                  {{ cancelledSubscription.name }} - ${{ cancelledSubscription.payment }}/month
                </v-chip>
              </template>
            </div>
            <p class="font-weight-semibold">
              When you uninstall a module
            </p>
            <p class="text-sm mb-0">
              <ol>
                <li class="mb-1">
                  Customer will only lose access to the module, data will still remain until next re-install.
                </li>
              </ol>
            </p>
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="error"
              block
              :loading="isCancelSubscriptionLoading"
              :disabled="isCancelSubscriptionLoading"
              class="mt-3"
              @click="deleteSubscription()"
            >
              Cancel subscription
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import {
  ref,
  inject,
  onMounted,
} from '@vue/composition-api'
import {
  mdiDotsVertical,
  mdiWrenchOutline,
  mdiDeleteOutline,
  mdiNotebookEditOutline,
  mdiFaceAgent,
  mdiBookOpen,
  mdiHumanGreeting,
  mdiMonitorDashboard,
  mdiAccountQuestionOutline,
  mdiClose,
} from '@mdi/js'
import { useRouter } from '@core/utils'

export default {
  props: {
    customer: {
      type: Object,
      default: null,
    },
    fetchBilling: {
      type: Function,
      default: null,
    },
  },
  setup(props) {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const { route } = useRouter()
    const customerId = route.value.params.id

    // Properties
    const plansList = ref([])
    const cancelledSubscription = ref(null)
    const isCancelSubscriptionDialogOpen = ref(false)
    const isCancelSubscriptionLoading = ref(false)

    // Methods
    const fetchSubscriptions = id => {
      store
        .dispatch('customerStore/fetchSubscriptions', { id })
        .then(response => {
          plansList.value = response.data.data
        })
        .catch(error => snackbarService.error(error.response.data.message || 'Something went wrong. Please refresh!'))
    }
    const createSubscription = plan => {
      store
        .dispatch('customerStore/createSubscription', { id: customerId, subscription_type_id: plan.id })
        .then(response => {
          snackbarService.success(response.data.message)
          fetchSubscriptions(customerId)
          props.fetchBilling(customerId)
        })
        .catch(error => snackbarService.error(error.response.data.message || 'Something went wrong. Please refresh!'))
    }
    const deleteSubscription = () => {
      isCancelSubscriptionLoading.value = true

      store
        .dispatch('customerStore/deleteSubscription', { id: customerId, subscription_type_id: cancelledSubscription.value.id })
        .then(response => {
          snackbarService.success(response.data.message)
          fetchSubscriptions(customerId)
          props.fetchBilling(customerId)
          isCancelSubscriptionLoading.value = false
          isCancelSubscriptionDialogOpen.value = false
        })
        .catch(error => {
          isCancelSubscriptionLoading.value = false
          snackbarService.error(error.response.data.errors[0] || error.response.data.message)
        })
    }
    const moduleIcon = module => {
      switch (module.name) {
        case 'Clerical':
          return mdiNotebookEditOutline
        case 'CRM':
          return mdiFaceAgent
        case 'Accounting':
          return mdiBookOpen
        case 'Human Resources':
          return mdiHumanGreeting
        case 'Project Management':
          return mdiMonitorDashboard
        default:
          return mdiAccountQuestionOutline
      }
    }

    // Mounted
    onMounted(() => {
      if (customerId) fetchSubscriptions(customerId)
    })

    return {
      // Properties
      plansList,
      cancelledSubscription,
      isCancelSubscriptionDialogOpen,
      isCancelSubscriptionLoading,

      // Icons
      icons: {
        mdiDotsVertical,
        mdiWrenchOutline,
        mdiDeleteOutline,
        mdiClose,
        mdiNotebookEditOutline,
        mdiFaceAgent,
        mdiBookOpen,
        mdiHumanGreeting,
        mdiMonitorDashboard,
        mdiAccountQuestionOutline,
      },

      // Methods
      createSubscription,
      deleteSubscription,
      moduleIcon,
    }
  },
}
</script>

<style lang="scss">
  @import '~@core/preset/preset/mixins.scss';

  .primary-border {
    border: 2px solid var(--v-primary-base) !important;
  }
</style>
