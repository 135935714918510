<template>
  <v-row>
    <v-col
      cols="12"
      sm="6"
      md="4"
      class="tutorial-group-items"
    >
      <list-draggable
        :tutorial-group="tutorialGroup"
        :tutorial="tutorial"
        :icons="icons"
        :fetch-tutorial="fetchTutorial"
        :update-list="updateList"
        @deselect-video="editorState = false"
      />
      <v-card
        v-if="$vuetify.breakpoint.mdAndUp"
        class="mt-5"
      >
        <v-card-text>
          <v-btn
            class="mb-4"
            color="primary"
            block
            :loading="saveTutorialLoading"
            :disabled="saveTutorialLoading || !saveTutorialValid"
            @click="saveTutorial"
          >
            Save
          </v-btn>
          <v-btn
            v-if="tutorial.id"
            color="error"
            block
            :disabled="saveTutorialLoading"
            @click="isDeleteDialogOpen = true"
          >
            Delete
          </v-btn>
          <v-btn
            v-else
            color="secondary"
            block
            outlined
            :to="{ name: 'tutorial-group' }"
          >
            Back
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col
      cols="12"
      sm="6"
      md="8"
    >
      <v-card>
        <v-card-title class="d-flex align-center">
          <div class="text-h6">
            {{ tutorial.id ? 'Edit' : 'New ' }} Tutorial Title
          </div>
          <v-spacer></v-spacer>
          <v-btn
            v-if="tutorial.id"
            icon
            small
            @click="editorState = false; tutorial = { title: '', content: '', published_at: `${yearNow}-${monthNow}-${dayNow}` }; saveTutorialForm.resetValidation()"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-form
          ref="saveTutorialForm"
          v-model="saveTutorialValid"
        >
          <v-row class="px-5">
            <v-col
              cols="12"
              md="8"
            >
              <v-text-field
                v-model="tutorial.title"
                placeholder="Title"
                :rules="[validators.required]"
                dense
                outlined
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-menu
                v-model="isPublishedAtMenuOpen"
                :close-on-content-click="false"
                :nudge-left="$vuetify.breakpoint.smAndDown ? 145 : 0"
                transition="scale-transition"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="publishedAtDateFormatted"
                    class="header-inputs"
                    placeholder="Date"
                    :append-icon="icons.mdiCalendar"
                    :rules="[validators.required]"
                    readonly
                    outlined
                    dense
                    hide-details="auto"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="tutorial.published_at"
                  color="primary"
                  :first-day-of-week="1"
                  @input="isPublishedAtMenuOpen= false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-card-title class="d-flex align-center">
            <div class="text-h6">
              {{ tutorial.id ? 'Edit' : 'New ' }} Tutorial Content
            </div>
          </v-card-title>

          <v-card-text>
            <div
              class="mb-0"
              @click="editorState = true"
            >
              <vue-editor
                v-model="tutorial.content"
                placeholder="Add your text here..."
                :editor-toolbar="customToolBar"
                :custom-modules="customEditorModules"
                :editor-options="editorSettings"
                :class="{ 'dark-mode-toolbar': $vuetify.theme.dark, 'deselect-video' : !editorState }"
              />
            </div>
          </v-card-text>
        </v-form>
      </v-card>
    </v-col>

    <v-col
      cols="12"
      sm="6"
      md="4"
      class="tutorial-group-items"
    >
      <v-card v-if="$vuetify.breakpoint.smAndDown">
        <v-card-text>
          <v-btn
            class="mb-4"
            color="primary"
            block
            :loading="saveTutorialLoading"
            :disabled="saveTutorialLoading || !saveTutorialValid"
            @click="saveTutorial"
          >
            Save
          </v-btn>
          <v-btn
            v-if="tutorial.id"
            color="error"
            block
            :disabled="saveTutorialLoading"
            @click="isDeleteDialogOpen = true"
          >
            Delete
          </v-btn>
          <v-btn
            v-else
            color="secondary"
            block
            outlined
            :to="{ name: 'tutorial-group' }"
          >
            Back
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>

    <!-- Delete Dialog -->
    <v-dialog
      v-model="isDeleteDialogOpen"
      width="500"
      persistent
    >
      <v-card v-if="tutorial && tutorialGroup">
        <v-card-title class="d-flex align-center mv-4">
          Delete {{ tutorial.title }}?
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isDeleteDialogOpen = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          This tutorial content will be removed from {{ tutorialGroup.title }}.
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="error"
            block
            :loading="deleteTutorialLoading"
            :disabled="deleteTutorialLoading"
            class="mt-3"
            @click="deleteTutorial(tutorial.id)"
          >
            Yes, remove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  mdiPencil,
  mdiCircleOutline,
  mdiCalendar,
  mdiClose,
} from '@mdi/js'
import {
  onMounted,
  ref,
  inject,
  computed,
} from '@vue/composition-api'
import { useRouter } from '@core/utils'
import { required } from '@core/utils/validation'
import { VueEditor } from 'vue2-editor'
import VideoResize from 'quill-video-resize-module2'
import ListDraggable from '@/components/tutorial/ListDraggable.vue'

export default {
  components: {
    VueEditor,
    ListDraggable,
  },
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const utilsService = inject('utilsService')
    const { route } = useRouter()
    const tutorialGroupId = route.value.params.id

    // Properties
    const [dayNow, monthNow, yearNow] = new Date().toLocaleDateString('en-SG').split('/')
    const tutorialGroup = ref(null)
    const tutorial = ref({
      title: '',
      content: '',
      published_at: `${yearNow}-${monthNow}-${dayNow}`,
    })

    const isPublishedAtMenuOpen = ref(false)
    const saveTutorialForm = ref(null)
    const saveTutorialValid = ref(false)
    const saveTutorialLoading = ref(false)

    const isDeleteDialogOpen = ref(false)
    const deleteTutorialLoading = ref(false)

    const editorState = ref(false)
    const customToolBar = [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' },
      ],
      ['image', 'video', 'link'],
    ]
    const customEditorModules = [
      { alias: 'VideoResize', module: VideoResize },
    ]
    const editorSettings = {
      modules: {
        VideoResize: {
          handleStyles: {
            backgroundColor: 'black',
            border: 'none',
          },
        },
      },
    }

    // Computed
    const publishedAtDateFormatted = computed(() => utilsService.formatDate(tutorial.value.published_at))

    // Methods
    const fetchTutorialGroup = id => {
      store
        .dispatch('tutorialStore/fetchTutorialGroup', { id })
        .then(response => {
          tutorialGroup.value = response.data.data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching tutorial group. Please refresh!')
        })
    }
    const fetchTutorial = id => {
      store
        .dispatch('tutorialStore/fetchTutorial', { id })
        .then(response => {
          tutorial.value = response.data.data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching tutorial. Please refresh!')
        })
    }
    const saveTutorial = () => {
      saveTutorialLoading.value = true
      tutorial.value.tutorial_group_id = tutorialGroupId
      store
        .dispatch(`tutorialStore/${tutorial.value.id ? 'updateTutorial' : 'createTutorial'}`, tutorial.value)
        .then(response => {
          snackbarService.success(response.data.message)
          saveTutorialLoading.value = false
          saveTutorialForm.value.resetValidation()
          fetchTutorialGroup(tutorialGroupId)
          tutorial.value = {
            title: '',
            content: '',
            published_at: `${yearNow}-${monthNow}-${dayNow}`,
          }
          editorState.value = false
        })
        .catch(error => {
          saveTutorialLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while saving tutorial. Please refresh!')
        })
    }
    const deleteTutorial = id => {
      deleteTutorialLoading.value = true
      store
        .dispatch('tutorialStore/deleteTutorial', { id })
        .then(response => {
          snackbarService.success(response.data.message)
          deleteTutorialLoading.value = false
          isDeleteDialogOpen.value = false
          saveTutorialForm.value.resetValidation()
          fetchTutorialGroup(tutorialGroupId)
          tutorial.value = {
            title: '',
            content: '',
            published_at: `${yearNow}-${monthNow}-${dayNow}`,
          }
          editorState.value = false
        })
        .catch(error => {
          deleteTutorialLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while deleting tutorial. Please refresh!')
        })
    }
    const reorderTutorialList = ids => {
      store
        .dispatch('tutorialStore/reorderTutorialList', { tutorial_ids: ids })
        .then(response => {
          snackbarService.success(response.data.message)
          fetchTutorialGroup(tutorialGroupId)
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while reorder tutorial list. Please refresh!')
        })
    }
    const updateList = () => {
      const tutorialList = []
      tutorialGroup.value.tutorials.forEach(item => {
        tutorialList.push(item.id)
      })
      reorderTutorialList(tutorialList)
    }

    // Mounted
    onMounted(() => {
      if (tutorialGroupId) fetchTutorialGroup(tutorialGroupId)
    })

    return {
      // Computed
      publishedAtDateFormatted,

      // Properties
      tutorialGroup,
      tutorial,

      dayNow,
      monthNow,
      yearNow,

      isPublishedAtMenuOpen,
      saveTutorialForm,
      saveTutorialValid,
      saveTutorialLoading,

      isDeleteDialogOpen,
      deleteTutorialLoading,

      editorState,
      customToolBar,
      customEditorModules,
      editorSettings,

      // Validators
      validators: {
        required,
      },

      // Icons
      icons: {
        mdiPencil,
        mdiCircleOutline,
        mdiCalendar,
        mdiClose,
      },

      // Methods
      fetchTutorial,
      saveTutorial,
      deleteTutorial,
      updateList,
    }
  },
}
</script>

<style lang="scss">
.tutorial-group-items {
  .theme--light.v-sheet .v-card__title {
    color: white !important;
  }

  .v-card {
    display: flex !important;
    flex-direction: column;
    max-height: calc(100vh - 250px) !important;

    .v-card__title {
      background-color: #019267 !important;
      font-size: 18px !important;
    }

    .v-card__text {
      flex-grow: 1;
      overflow: auto;
    }
  }

  .v-list-item__content > * {
    line-height: 1.5;
  }
  .flip-list-move {
    transition: transform 0.5s;
  }
  .ghost {
    border-radius: 2px;
  }
  .ghost p, .ghost span {
    opacity: 0;
  }
  .theme--light {
    .ghost {
      background-color: #EEEEEE;
    }
  }
  .theme--dark {
    .ghost {
      background-color: #474461;
    }
  }
}
</style>

<style>
.ql-editor iframe {
  pointer-events: none;
}
.deselect-video .ql-container div:last-child {
  display: none;
}
</style>
