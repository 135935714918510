import CryptoJS from 'crypto-js'

const cryptKey = process.env.VUE_APP_CRYPT_KEY

export default {
  encrypt(payload) {
    return CryptoJS.AES.encrypt(payload, cryptKey).toString()
  },
  decrypt(payload) {
    return CryptoJS.AES.decrypt(payload, cryptKey).toString(CryptoJS.enc.Utf8)
  },
}
