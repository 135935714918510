<template>
  <div>
    <v-card>
      <v-card-title>
        Payment History
      </v-card-title>

      <v-data-table
        :headers="tableColumnHeaders"
        :items="payments"
        hide-default-footer
        fixed-header
        :items-per-page="50"
        :loading="fetchLoading"
        mobile-breakpoint="0"
        class="text-no-wrap fixed-action d-none d-sm-block"
      >
        <!-- Actions -->
        <template #[`item.actions`]="{item}">
          <v-btn
            icon
            class="me-2"
            :loading="downloadPaymentReceiptLoading"
            @click="downloadPaymentReceipt(item)"
          >
            <v-icon>{{ icons.mdiDownload }}</v-icon>
          </v-btn>
        </template>
      </v-data-table>

      <!-- table view for mobile -->
      <v-data-table
        :headers="tableSmColumnHeaders"
        :items="payments"
        hide-default-footer
        fixed-header
        :items-per-page="50"
        :expanded.sync="expanded"
        single-expand
        show-expand
        :loading="fetchLoading"
        mobile-breakpoint="0"
        class="text-no-wrap d-block d-sm-none"
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td
            class="table-expand"
            :colspan="headers.length"
          >
            <p class="mt-2">
              Payment Date: {{ item.created_at }}
            </p>
            <p>Description:</p>
            <div class="d-flex">
              <p
                class="text-wrap"
                v-html="item.description"
              ></p>
              <v-spacer></v-spacer>
              <v-btn
                icon
                class="me-2"
                :loading="downloadPaymentReceiptLoading"
                @click="downloadPaymentReceipt(item)"
              >
                <v-icon>{{ icons.mdiDownload }}</v-icon>
              </v-btn>
            </div>
          </td>
        </template>
      </v-data-table>

      <v-card-text class="pt-5">
        <v-row>
          <v-col
            cols="4"
            sm="2"
          >
            <div class="d-flex align-center">
              <v-select
                v-model="tablePagination.per"
                :items="listLengthOptions"
                single-line
                outlined
                dense
                hide-details
                class="invoice-list-row-selection"
                @input="listLengthChange()"
              ></v-select>
              <div class="pl-3">
                Rows
              </div>
            </div>
          </v-col>

          <v-spacer></v-spacer>

          <v-col
            sm="10"
            cols="4"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="tablePagination.current_page"
              total-visible="6"
              :length="tablePagination.total_page"
              @input="fetchPayments"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  ref,
  inject,
  onMounted,
} from '@vue/composition-api'
import {
  mdiDownload,
} from '@mdi/js'
import { useRouter } from '@core/utils'

export default {
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const { route } = useRouter()
    const customerId = route.value.params.id

    // Properties
    const payments = ref([])
    const downloadPaymentReceiptLoading = ref(false)

    // Table Handlers
    const fetchLoading = ref(false)
    const expanded = ref([])
    const listLengthOptions = ref([5, 25, 50])
    const tablePagination = ref({
      current_page: 1,
      per: 5,
      total_count: 0,
      total_page: 0,
    })
    const tableColumnHeaders = [
      { text: 'RECEIPT NO.', value: 'payment_number', sortable: false },
      { text: 'DESCRIPTION', value: 'description', sortable: false },
      { text: 'PAYMENT DATE', value: 'created_at', sortable: false },
      { text: 'TOTAL', value: 'total', sortable: false },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]
    const tableSmColumnHeaders = [
      { text: 'RECEIPT NO.', value: 'payment_number', sortable: false },
      { text: 'TOTAL', value: 'total', sortable: false },
      { text: '', value: 'data-table-expand' },
    ]

    // Methods
    const fetchPayments = id => {
      fetchLoading.value = true
      store
        .dispatch('customerStore/fetchPayments', {
          customer_id: id,
          page: tablePagination.value.current_page,
          per: tablePagination.value.per,
        })
        .then(response => {
          const { data, pagination } = response.data
          payments.value = data
          tablePagination.value = pagination
          fetchLoading.value = false
        })
        .catch(error => {
          fetchLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong. Please refresh!')
        })
    }
    const listLengthChange = () => {
      tablePagination.value.current_page = 1
      fetchPayments()
    }
    const downloadPaymentReceipt = payment => {
      downloadPaymentReceiptLoading.value = true
      store
        .dispatch('customerStore/downloadPayment', { id: payment.id, customer_id: customerId })
        .then(response => {
          const url = window.URL.createObjectURL(response)
          const a = document.createElement('a')
          a.href = url
          a.download = `${payment.payment_number}-${payment.description}.pdf`
          document.body.appendChild(a)
          a.click()
          a.remove()
          downloadPaymentReceiptLoading.value = false
        })
        .catch(error => {
          downloadPaymentReceiptLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while downloading payment receipt. Please refresh!')
        })
    }

    // Mounted
    onMounted(() => {
      if (customerId) fetchPayments(customerId)
    })

    return {
      // Properties
      payments,
      downloadPaymentReceiptLoading,

      // Table Handlers
      fetchLoading,
      expanded,
      tablePagination,
      listLengthOptions,
      tableColumnHeaders,
      tableSmColumnHeaders,

      // Icons
      icons: {
        mdiDownload,
      },

      // Methods
      fetchPayments,
      listLengthChange,
      downloadPaymentReceipt,
    }
  },
}
</script>
