<template>
  <div>
    <v-card>
      <v-card-text class="d-flex align-center pb-5">
        <v-row>
          <v-col
            cols="12"
            sm="3"
            lg="2"
          >
            <!-- create module -->
            <v-btn
              color="primary"
              class="me-3"
              @click="isSaveDialogOpen = true"
            >
              <v-icon
                size="18"
                class="me-1"
              >
                {{ icons.mdiPlus }}
              </v-icon>
              <span>Create module</span>
            </v-btn>

            <!-- Save Dialog -->
            <v-dialog
              v-model="isSaveDialogOpen"
              width="500"
              persistent
            >
              <v-card>
                <v-card-title class="d-flex align-center mv-4 mb-4">
                  {{ subscriptionType.id ? 'Edit' : 'New' }} Module
                  <v-spacer></v-spacer>
                  <v-btn
                    icon
                    small
                    @click="isSaveDialogOpen = false; saveForm.reset(); subscriptionType = {}"
                  >
                    <v-icon size="22">
                      {{ icons.mdiClose }}
                    </v-icon>
                  </v-btn>
                </v-card-title>

                <v-card-text>
                  <v-form
                    ref="saveForm"
                    v-model="saveValid"
                  >
                    <v-text-field
                      v-model="subscriptionType.name"
                      outlined
                      dense
                      label="Name"
                      :rules="[validators.required]"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="subscriptionType.payment"
                          outlined
                          dense
                          label="Payment"
                          prefix="BND$"
                          type="number"
                          :rules="paymentRules"
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="subscriptionType.promotion"
                          outlined
                          dense
                          label="Promotion"
                          prefix="BND$"
                          type="number"
                          :rules="promoRules"
                          hide-details="auto"
                          class="mb-6"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-select
                      v-model="subscriptionType.status"
                      :items="['Active', 'Inactive']"
                      outlined
                      dense
                      label="Status"
                      :rules="[validators.required]"
                      hide-details="auto"
                      class="mb-6"
                    ></v-select>
                    <v-text-field
                      v-model="subscriptionType.icon"
                      outlined
                      dense
                      label="Icon"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-btn
                    color="primary"
                    block
                    :loading="saveModuleLoading"
                    :disabled="saveModuleLoading || !saveValid"
                    @click="saveModule"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>

          <v-spacer></v-spacer>

          <v-col
            cols="12"
            sm="3"
            lg="2"
            class="pr-sm-0"
          >
            <div class="d-flex align-center">
              <!-- filter status -->
              <v-select
                v-model="statusFilter"
                :items="statusOptions"
                single-line
                outlined
                dense
                hide-details
                clearable
                placeholder="Select Status"
              ></v-select>
            </div>
          </v-col>

          <v-col
            cols="12"
            sm="4"
            lg="3"
            class="d-flex pl-sm-0"
          >
            <!-- search module -->
            <v-tooltip
              :left="$vuetify.breakpoint.smAndUp"
              :top="$vuetify.breakpoint.xsOnly"
              :nudge-bottom="$vuetify.breakpoint.smAndUp ? 20 : 0"
              :nudge-top="$vuetify.breakpoint.xsOnly ? 5 : 0"
              :nudge-right="$vuetify.breakpoint.xsOnly ? 100 : 0"
              max-width="250"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  size="30"
                  class="mx-3 my-auto hover-pointer d-none d-sm-block"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="25">
                    {{ icons.mdiAlertCircleOutline }}
                  </v-icon>
                </v-avatar>
              </template>
              <h3 class="my-2">
                How to use the search bar
              </h3>
              <p class="mb-1">
                You can search using:
              </p>
              <ul class="mb-2">
                <li>
                  Module name
                </li>
              </ul>
            </v-tooltip>
            <v-text-field
              v-model="searchText"
              :append-icon="icons.mdiMagnify"
              single-line
              dense
              outlined
              hide-details
              placeholder="Search Module"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="pt-5">
        <v-row>
          <v-col
            cols="4"
            sm="2"
          >
            <div class="d-flex align-center">
              <v-select
                v-model="tablePagination.per"
                :items="listLengthOptions"
                single-line
                outlined
                dense
                hide-details
                @input="listLengthChange()"
              ></v-select>
              <div class="pl-3">
                Rows
              </div>
            </div>
          </v-col>

          <v-spacer></v-spacer>

          <v-col
            sm="10"
            cols="4"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="tablePagination.current_page"
              total-visible="6"
              :length="tablePagination.total_page"
              @input="fetchModules"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- table -->
      <v-data-table
        :headers="tableHeaders"
        :items="subscriptionTypes"
        :options.sync="options"
        :items-per-page="100"
        :loading="loading"
        mobile-breakpoint="0"
        hide-default-footer
        class="text-no-wrap fixed-action d-none d-sm-block"
      >
        <!-- Status -->
        <template #[`item.status`]="{item}">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                size="30"
                :color="moduleStatus(item.status).variant"
                :class="`v-avatar-light-bg ${moduleStatus(item.status).variant}--text`"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon
                  size="18"
                  :color="moduleStatus(item.status).variant"
                >
                  {{ moduleStatus(item.status).icon }}
                </v-icon>
              </v-avatar>
            </template>
            <span>{{ item.status }}</span>
          </v-tooltip>
        </template>

        <!-- Payment -->
        <template #[`item.payment`]="{item}">
          <div>
            ${{ item.payment }}
          </div>
        </template>

        <!-- Promotion -->
        <template #[`item.promotion`]="{item}">
          <div>
            ${{ item.promotion || '0.00' }}
          </div>
        </template>

        <!-- Actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="me-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="fetchModule(item.id); isSaveDialogOpen = true">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="subscriptionType = item; isDeleteDialogOpen = true">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>

      <!-- table view for mobile -->
      <v-data-table
        :headers="tableSmHeaders"
        :items="subscriptionTypes"
        :options.sync="options"
        :items-per-page="100"
        :loading="loading"
        :expanded.sync="expanded"
        single-expand
        show-expand
        mobile-breakpoint="0"
        hide-default-footer
        class="text-no-wrap d-block d-sm-none"
      >
        <!-- Status -->
        <template #[`item.status`]="{item}">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                size="30"
                :color="moduleStatus(item.status).variant"
                :class="`v-avatar-light-bg ${moduleStatus(item.status).variant}--text`"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon
                  size="18"
                  :color="moduleStatus(item.status).variant"
                >
                  {{ moduleStatus(item.status).icon }}
                </v-icon>
              </v-avatar>
            </template>
            <span>{{ item.status }}</span>
          </v-tooltip>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td
            class="table-expand"
            :colspan="headers.length"
          >
            <p class="mt-2">
              Payment: ${{ item.payment }}
            </p>
            <p>Promotion: ${{ item.promotion }}</p>
            <p>Subscribers: {{ item.subscribers_count }}</p>
            <div class="d-flex">
              <p>Icon: {{ item.icon }}</p>
              <v-spacer></v-spacer>
              <v-menu
                bottom
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="me-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="fetchModule(item.id); isSaveDialogOpen = true">
                    <v-list-item-title>
                      <v-icon
                        size="16"
                        class="me-2"
                      >
                        {{ icons.mdiPencilOutline }}
                      </v-icon>
                      <span class="caption">Edit</span>
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="subscriptionType = item; isDeleteDialogOpen = true">
                    <v-list-item-title>
                      <v-icon
                        size="16"
                        class="me-2"
                      >
                        {{ icons.mdiDeleteOutline }}
                      </v-icon>
                      <span class="caption">Delete</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </td>
        </template>
      </v-data-table>

      <!-- Delete Dialog -->
      <v-dialog
        v-model="isDeleteDialogOpen"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title class="d-flex align-center mv-4">
            Delete {{ subscriptionType.name }}?
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              @click="isDeleteDialogOpen = false; subscriptionType = {}"
            >
              <v-icon size="22">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            This module will be removed from this list. It will no longer appear in searches or available for use.
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="error"
              block
              :loading="deleteModuleLoading"
              :disabled="deleteModuleLoading"
              class="mt-3"
              @click="deleteModule(subscriptionType.id)"
            >
              Yes, remove
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card-text class="pt-5">
        <v-row>
          <v-col
            cols="4"
            sm="2"
          >
            <div class="d-flex align-center">
              <v-select
                v-model="tablePagination.per"
                :items="listLengthOptions"
                single-line
                outlined
                dense
                hide-details
                @input="listLengthChange"
              ></v-select>
              <div class="pl-3">
                Rows
              </div>
            </div>
          </v-col>

          <v-spacer></v-spacer>

          <v-col
            sm="10"
            cols="4"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="tablePagination.current_page"
              total-visible="6"
              :length="tablePagination.total_page"
              @input="fetchModules"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiCheckCircleOutline,
  mdiAlertCircleOutline,
  mdiDotsVertical,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiMagnify,
  mdiClose,
} from '@mdi/js'
import {
  ref,
  inject,
  computed,
  watch,
} from '@vue/composition-api'
import { required } from '@core/utils/validation'

export default {
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')

    // Properties
    const subscriptionTypes = ref([])
    const subscriptionType = ref({})
    const searchText = ref('')
    const statusFilter = ref(null)
    const statusOptions = ref(['Active', 'Inactive'])

    const saveForm = ref(null)
    const saveValid = ref(false)
    const isSaveDialogOpen = ref(false)
    const saveModuleLoading = ref(false)

    const isDeleteDialogOpen = ref(false)
    const deleteModuleLoading = ref(false)

    // Table Handlers
    const loading = ref(false)
    const expanded = ref([])
    const options = ref({
      sortBy: ['name'],
      sortDesc: [false],
    })
    const listLengthOptions = ref([20, 50, 100])
    const tablePagination = ref({
      current_page: 1,
      per: 20,
      total_count: 0,
      total_page: 0,
    })
    const tableHeaders = computed(() => (
      [
        { text: 'NAME', value: 'name' },
        {
          text: 'STATUS',
          value: 'status',
          align: 'center',
          sortable: false,
        },
        {
          text: 'PAYMENT',
          value: 'payment',
          align: 'center',
          sortable: false,
        },
        {
          text: 'PROMOTION',
          value: 'promotion',
          align: 'center',
          sortable: false,
        },
        {
          text: 'SUBSCRIBERS',
          value: 'subscribers_count',
          align: 'center',
          sortable: false,
        },
        { text: 'ICON', value: 'icon', sortable: false },
        {
          text: 'ACTIONS',
          value: 'actions',
          align: 'right',
          sortable: false,
        },
      ]
    ))
    const tableSmHeaders = computed(() => (
      [
        { text: 'NAME', value: 'name' },
        {
          text: 'STATUS',
          value: 'status',
          align: 'center',
          sortable: false,
        },
        { text: '', value: 'data-table-expand' },
      ]
    ))

    // Validators
    const paymentRules = [
      v => /^\d+(\.\d+)?$/.test(v) || 'Positive value required',
    ]
    const promoRules = [
      v => (!v || /^\d+(\.\d+)?$/.test(v)) || 'Positive value required',
    ]

    // Methods
    const fetchModules = () => {
      loading.value = true
      store
        .dispatch('subscriptionTypeStore/fetchModules', {
          page: tablePagination.value.current_page,
          per: tablePagination.value.per,
          sort_by: options.value.sortBy[0],
          sort_dir: options.value.sortDesc[0] ? 'desc' : 'asc',
          search_text: searchText.value,
          status: statusFilter.value,
        })
        .then(response => {
          const { data, pagination } = response.data
          subscriptionTypes.value = data
          tablePagination.value = pagination
          loading.value = false
        })
        .catch(error => {
          loading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching modules. Please refresh!')
        })
    }
    const fetchModule = id => {
      store
        .dispatch('subscriptionTypeStore/fetchModule', { id })
        .then(response => {
          subscriptionType.value = response.data.data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching module. Please refresh!')
        })
    }
    const listLengthChange = () => {
      tablePagination.value.current_page = 1
      fetchModules()
    }
    const saveModule = () => {
      saveModuleLoading.value = true
      store
        .dispatch(`subscriptionTypeStore/${subscriptionType.value.id ? 'updateModule' : 'createModule'}`, subscriptionType.value)
        .then(response => {
          snackbarService.success(response.data.message)
          fetchModules()
          isSaveDialogOpen.value = false
          saveModuleLoading.value = false
          saveForm.value.reset()
          subscriptionType.value = {}
        })
        .catch(error => {
          saveModuleLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while adding module. Please refresh!')
        })
    }
    const deleteModule = moduleId => {
      deleteModuleLoading.value = true
      store
        .dispatch('subscriptionTypeStore/deleteModule', { id: moduleId })
        .then(response => {
          snackbarService.success(response.data.message)
          fetchModules()
          isDeleteDialogOpen.value = false
          deleteModuleLoading.value = false
          subscriptionType.value = {}
        })
        .catch(error => {
          deleteModuleLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while deleting module. Please refresh!')
        })
    }
    const moduleStatus = status => {
      let variantAndIcon = {}
      switch (status) {
        case 'Active':
          variantAndIcon = { variant: 'success', icon: mdiCheckCircleOutline }
          break
        case 'Inactive':
          variantAndIcon = { variant: 'error', icon: mdiAlertCircleOutline }
          break
        default:
          variantAndIcon = { variant: 'secondary', icon: mdiClose }
      }

      return variantAndIcon
    }

    // Watch
    let timer = null
    watch([searchText, statusFilter, options], () => {
      if (timer) {
        clearTimeout(timer)
        timer = null
      }
      timer = setTimeout(() => {
        fetchModules()
      }, 200)
    })

    return {
      // Properties
      subscriptionTypes,
      subscriptionType,
      searchText,
      statusFilter,
      statusOptions,

      saveForm,
      saveValid,
      isSaveDialogOpen,
      saveModuleLoading,

      isDeleteDialogOpen,
      deleteModuleLoading,

      // Table Handlers
      loading,
      expanded,
      options,
      listLengthOptions,
      tablePagination,
      tableHeaders,
      tableSmHeaders,

      // Validators
      validators: {
        required,
      },
      paymentRules,
      promoRules,

      // Icons
      icons: {
        mdiPlus,
        mdiCheckCircleOutline,
        mdiAlertCircleOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiMagnify,
        mdiClose,
      },

      // Methods
      fetchModules,
      fetchModule,
      listLengthChange,
      saveModule,
      deleteModule,
      moduleStatus,
    }
  },
}
</script>
