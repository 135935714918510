import { axios } from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTutorialGroups(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/admin/tutorial_groups', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTutorialGroup(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/admin/tutorial_groups/${params.id}`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createTutorialGroup(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/admin/tutorial_groups', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTutorialGroup(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/admin/tutorial_groups/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteTutorialGroup(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/admin/tutorial_groups/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTutorial(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/admin/tutorials/${params.id}`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createTutorial(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/admin/tutorials', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTutorial(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/admin/tutorials/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteTutorial(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/admin/tutorials/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    reorderTutorialList(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/v1/admin/tutorials/reorder', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
