<template>
  <v-row class="mt-2">
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <v-avatar
            color="primary"
            class="v-avatar-light-bg primary--text mb-4"
            size="120"
            rounded
          >
            <v-img :src="customer.logo_url">
              <template v-slot:placeholder>
                <v-sheet>
                  <v-img
                    src="@/assets/images/logo.png"
                  ></v-img>
                </v-sheet>
              </template>
            </v-img>
          </v-avatar>
          <span class="mb-2">{{ customer.display_name }}</span>
        </v-card-title>
        <v-card-text class="mt-5">
          <div class="d-flex">
            <v-card-title class="pl-0 pr-2">
              Billing Info
            </v-card-title>
            <v-menu
              bottom
              right
              transition="scale-transition"
              origin="top left"
            >
              <template v-slot:activator="{ on }">
                <v-chip
                  label
                  color="primary"
                  :class="`v-chip-light-bg my-auto ${statusTextColor}--text`"
                  v-on="on"
                >
                  {{ billing.status }}
                </v-chip>
              </template>
              <v-card class="px-5 pt-1">
                <v-radio-group v-model="billing.status">
                  <v-radio
                    v-for="n in statusOptions"
                    :key="n"
                    :label="n"
                    :value="n"
                    :color="statusTextColor"
                  ></v-radio>
                </v-radio-group>
              </v-card>
            </v-menu>
          </div>
          <v-row
            align="end"
            justify="center"
            class="my-5 primary--text"
          >
            <div class="subtitle-1 font-weight-bold">
              $
            </div>
            <div class="display-2 font-weight-bold">
              {{ Math.round(billingTotal) }}
            </div>
            <div class="subtitle-1 font-weight-bold">
              .00/{{ billing.payment_period === 'Monthly' ? 'month' : 'year' }}
            </div>
          </v-row>
          <div>
            <v-menu
              v-model="isStartDateMenuOpen"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              eager
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startDateFormatted"
                  :append-icon="icons.mdiCalendar"
                  class="header-inputs flex-grow-0 mb-3"
                  readonly
                  outlined
                  dense
                  label="Start Date"
                  hide-details="auto"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="billing.start_date"
                color="primary"
                :first-day-of-week="1"
                @input="isStartDateMenuOpen = false; endDateRef.validate(); paymentPeriodRef.validate()"
              ></v-date-picker>
            </v-menu>
            <v-menu
              v-model="isEndDateMenuOpen"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              eager
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  ref="endDateRef"
                  v-model="endDateFormatted"
                  :append-icon="icons.mdiCalendar"
                  class="header-inputs flex-grow-0 mb-3"
                  readonly
                  outlined
                  dense
                  label="End Date"
                  hide-details="auto"
                  v-bind="attrs"
                  :rules="endDateRules"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="billing.end_date"
                color="primary"
                :first-day-of-week="1"
                @input="isEndDateMenuOpen = false; paymentPeriodRef.validate()"
              ></v-date-picker>
            </v-menu>
            <v-text-field
              v-model="billing.discount"
              outlined
              dense
              label="Discount"
              prefix="BND$"
              type="number"
              :rules="discountRules"
              hide-details="auto"
              class="mb-3"
              @input="calculateTotal"
            ></v-text-field>
            <v-select
              ref="paymentPeriodRef"
              v-model="billing.payment_period"
              :items="paymentPeriodOptions"
              outlined
              dense
              hide-details="auto"
              label="Payment Period"
              class="mb-3"
              :rules="paymentPeriodRules"
              @input="calculateTotal"
            ></v-select>
            <v-textarea
              v-model="billing.notes"
              outlined
              rows="3"
              hide-details="auto"
              label="Notes"
              class="mb-3"
            ></v-textarea>
            <v-btn
              color="primary"
              class="mt-3"
              width="100%"
              depressed
              :loading="loading"
              :disabled="loading"
              @click="updateBilling"
            >
              Save
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {
  ref,
  inject,
  computed,
  onMounted,
} from '@vue/composition-api'
import {
  mdiCalendar,
} from '@mdi/js'
import { useRouter } from '@core/utils'

export default {
  props: {
    customer: {
      type: Object,
      default: null,
    },
  },
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const utilsService = inject('utilsService')
    const { route } = useRouter()
    const customerId = route.value.params.id

    // Properties
    const billing = ref({})
    const billingTotal = ref(null)
    const initialBilling = ref({})
    const loading = ref(false)

    const isStartDateMenuOpen = ref(false)
    const isEndDateMenuOpen = ref(false)
    const endDateRef = ref(null)

    const paymentPeriodRef = ref(null)
    const paymentPeriodOptions = ref(['Monthly', 'Annually'])
    const statusMenu = ref(false)
    const statusOptions = ref(['Active', 'Expired', 'Trial'])

    // Computed
    const changeTextColor = () => {
      switch (billing.value.status) {
        case 'Active':
          return 'primary'
        case 'Trial':
          return 'warning'
        case 'Expired':
          return 'error'
        default:
          return ''
      }
    }
    const validateAnnualDate = () => {
      const startDate = new Date(billing.value.start_date)
      let oneYearFromNow = new Date(startDate.setFullYear(startDate.getFullYear() + 1))
      oneYearFromNow = new Date(oneYearFromNow.setDate(oneYearFromNow.getDate() - 1))

      if (new Date(billing.value.end_date) < oneYearFromNow) {
        return false
      }

      return true
    }
    const validatePaymentDate = () => {
      const startDate = new Date(billing.value.start_date)
      const endDate = new Date(billing.value.end_date)
      if (startDate > endDate) {
        return false
      }

      return true
    }
    const startDateFormatted = computed(() => utilsService.formatDate(billing.value.start_date, 'start'))
    const endDateFormatted = computed(() => utilsService.formatDate(billing.value.end_date, 'end'))
    const statusTextColor = computed(() => changeTextColor())
    const isEndDateOneYear = computed(() => validateAnnualDate())
    const startDateBeforeEndDate = computed(() => validatePaymentDate())

    // Validators
    const discountRules = [
      v => v >= 0 || 'Positive value required',
    ]
    const paymentPeriodRules = [
      v => v === 'Monthly'
      || (v === 'Annually' && isEndDateOneYear.value)
      || 'Please ensure that end date is at least 1 year away from the start date',
    ]
    const endDateRules = [
      v => (v && startDateBeforeEndDate.value) || 'Please select date after start date',
    ]

    // Methods
    const fetchBilling = id => {
      store
        .dispatch('customerStore/fetchBilling', { id })
        .then(response => {
          billing.value = response.data.data
          billingTotal.value = parseFloat(response.data.data.total - response.data.data.discount)
          initialBilling.value.payment_period = response.data.data.payment_period
        })
        .catch(error => snackbarService.error(error.response.data.message || 'Something went wrong. Please refresh!'))
    }
    const updateBilling = () => {
      if (billing.value.discount < 0) return
      if (billing.value.payment_period === 'Annually' && !isEndDateOneYear.value) return
      if (!startDateBeforeEndDate.value) return

      loading.value = true
      const billingParams = {
        id: customerId,
        start_date: billing.value.start_date,
        end_date: billing.value.end_date,
        discount: billing.value.discount,
        payment_period: billing.value.payment_period,
        notes: billing.value.notes,
        subscription_status: billing.value.status,
      }
      store
        .dispatch('customerStore/updateBilling', billingParams)
        .then(response => {
          snackbarService.success(response.data.message)
          loading.value = false
          fetchBilling(customerId)
        })
        .catch(error => {
          loading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while updating billing. Please refresh!')
        })
    }
    const resetBillingTotal = () => {
      billingTotal.value = parseFloat(billing.value.total)
    }
    const calculatePaymentPeriod = () => {
      if (initialBilling.value.payment_period === 'Annually' && billing.value.payment_period === 'Monthly') {
        billingTotal.value /= 10
      } else if (initialBilling.value.payment_period === 'Monthly' && billing.value.payment_period === 'Annually') {
        billingTotal.value *= 10
      }
    }
    const calculateDiscount = () => {
      if (billing.value.discount > 0) {
        if (billing.value.discount > billingTotal.value) {
          billingTotal.value = 0
        } else {
          billingTotal.value -= parseFloat(billing.value.discount)
        }
      }
    }
    const calculateTotal = () => {
      resetBillingTotal()
      calculatePaymentPeriod()
      calculateDiscount()
    }

    // Mounted
    onMounted(() => {
      if (customerId) fetchBilling(customerId)
    })

    return {
      // Computed
      startDateFormatted,
      endDateFormatted,
      statusTextColor,
      isEndDateOneYear,

      // Properties
      billing,
      billingTotal,
      initialBilling,
      loading,

      isStartDateMenuOpen,
      isEndDateMenuOpen,
      endDateRef,

      paymentPeriodRef,
      paymentPeriodOptions,
      statusMenu,
      statusOptions,

      // Validators
      discountRules,
      paymentPeriodRules,
      endDateRules,

      // Icons
      icons: {
        mdiCalendar,
      },

      // Methods
      updateBilling,
      calculateTotal,
    }
  },
}
</script>
