<template>
  <div id="user-view">
    <v-row>
      <v-col
        cols="12"
        md="5"
        lg="4"
      >
        <customer-billing
          ref="billingRef"
          :customer="customer"
        />
      </v-col>

      <v-col
        cols="12"
        md="7"
        lg="8"
      >
        <v-tabs
          v-model="customerTab"
          show-arrows
          class="user-tabs"
        >
          <v-tab>
            <v-icon
              size="20"
              class="me-3"
            >
              {{ icons.mdiDomain }}
            </v-icon>
            <span>Details</span>
          </v-tab>

          <v-tab>
            <v-icon
              size="20"
              class="me-3"
            >
              {{ icons.mdiChartBox }}
            </v-icon>
            <span>Statistics</span>
          </v-tab>

          <v-tab>
            <v-icon
              size="20"
              class="me-3"
            >
              {{ icons.mdiCreditCardOutline }}
            </v-icon>
            <span>Payment History</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="user-tabs-content"
          v-model="customerTab"
          class="mt-5 pa-1"
          touchless
        >
          <v-tab-item>
            <customer-details :customer="customer" />
            <customer-members
              :customer="customer"
              :fetch-customer="fetchCustomer"
              :fetch-billing="fetchBilling"
            />
            <customer-modules
              :customer="customer"
              :fetch-billing="fetchBilling"
            />
          </v-tab-item>
          <v-tab-item>
            <customer-stat />
          </v-tab-item>
          <v-tab-item>
            <customer-payments />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  ref,
  inject,
  onMounted,
} from '@vue/composition-api'
import {
  mdiDomain,
  mdiCreditCardOutline,
  mdiChartBox,
} from '@mdi/js'
import { useRouter } from '@core/utils'
import CustomerBilling from '@/components/customer/CustomerBilling.vue'
import CustomerDetails from '@/components/customer/CustomerDetails.vue'
import CustomerMembers from '@/components/customer/CustomerMembers.vue'
import CustomerModules from '@/components/customer/CustomerModules.vue'
import CustomerPayments from '@/components/customer/CustomerPayments.vue'
import CustomerStat from '@/components/customer/CustomerStat.vue'

export default {
  components: {
    CustomerBilling,
    CustomerDetails,
    CustomerMembers,
    CustomerModules,
    CustomerPayments,
    CustomerStat,
  },
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const { route } = useRouter()
    const customerId = route.value.params.id

    // Properties
    const customer = ref({})
    const customerTab = ref(null)
    const billingRef = ref(null)

    // Methods
    const fetchCustomer = id => {
      store
        .dispatch('customerStore/fetchCustomer', { id })
        .then(response => {
          customer.value = response.data.data
        })
        .catch(error => snackbarService.error(error.response.data.message || 'Something went wrong. Please refresh!'))
    }
    const fetchBilling = id => {
      store
        .dispatch('customerStore/fetchBilling', { id })
        .then(response => {
          billingRef.value.billing = response.data.data
          billingRef.value.billingTotal = response.data.data.total
        })
        .catch(error => snackbarService.error(error.response.data.message || 'Something went wrong. Please refresh!'))
    }

    // Mounted
    onMounted(() => {
      if (customerId) fetchCustomer(customerId)
    })

    return {
      // Properties
      customer,
      customerTab,
      billingRef,

      // Icons
      icons: {
        mdiDomain,
        mdiCreditCardOutline,
        mdiChartBox,
      },

      // Methods
      fetchCustomer,
      fetchBilling,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
