<template>
  <v-row>
    <v-col
      cols="12"
      md="9"
    >
      <v-card class="px-2 pb-8 mb-5">
        <v-card-title class="d-flex align-center">
          <div class="text-h6">
            {{ releaseNote.id ? 'Edit' : 'Add New ' }} Release Notes
          </div>
          <v-spacer></v-spacer>
          <v-btn
            v-if="releaseNote.id"
            icon
            small
            @click="releaseNote = { version: newVersionNumber(featuredReleaseNote.version), note: '', released_at: `${yearNow}-${monthNow}-${dayNow}` }"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-form
          ref="saveReleaseNoteForm"
          v-model="saveReleaseNoteValid"
        >
          <v-card-text>
            <div class="mb-0">
              <vue-editor
                v-model="releaseNote.note"
                placeholder="Add your release notes here..."
                :editor-toolbar="customToolBar"
                :class="{ 'dark-mode-toolbar': $vuetify.theme.dark }"
              />
            </div>
          </v-card-text>
          <v-row class="px-5">
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="releaseNote.version"
                placeholder="Version Number"
                :rules="versionRules"
                dense
                outlined
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-menu
                v-model="isReleasedAtMenuOpen"
                :close-on-content-click="false"
                :nudge-left="$vuetify.breakpoint.smAndDown ? 145 : 0"
                transition="scale-transition"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="releasedAtDateFormatted"
                    class="header-inputs"
                    placeholder="Date"
                    :append-icon="icons.mdiCalendar"
                    :rules="[validators.required]"
                    readonly
                    outlined
                    dense
                    hide-details="auto"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="releaseNote.released_at"
                  color="primary"
                  :first-day-of-week="1"
                  @input="isReleasedAtMenuOpen= false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-spacer></v-spacer>
            <v-col
              cols="12"
              md="3"
              lg="2"
            >
              <v-btn
                v-if="releaseNote.id"
                color="error"
                block
                :disabled="saveReleaseNoteLoading"
                @click="isDeleteDialogOpen = true"
              >
                Delete
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="3"
              lg="2"
            >
              <v-btn
                color="primary"
                block
                :loading="saveReleaseNoteLoading"
                :disabled="saveReleaseNoteLoading || !saveReleaseNoteValid || releaseNote.note === ''"
                @click="saveReleaseNote"
              >
                {{ releaseNote.id ? 'Update' : 'Save' }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>

      <!-- Delete Dialog -->
      <v-dialog
        v-model="isDeleteDialogOpen"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title class="d-flex align-center mv-4">
            Delete {{ releaseNote.version }}?
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              @click="isDeleteDialogOpen = false"
            >
              <v-icon size="22">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            This release note will be removed from this list. It will no longer appear on user dashboard.
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="error"
              block
              :loading="deleteReleaseNoteLoading"
              :disabled="deleteReleaseNoteLoading"
              class="mt-3"
              @click="deleteReleaseNote"
            >
              Yes, remove
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card class="px-2 pb-8">
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="4"
              sm="2"
            >
              <div class="d-flex align-center">
                <v-select
                  v-model="tablePagination.per"
                  :items="listLengthOptions"
                  single-line
                  outlined
                  dense
                  hide-details
                  @input="listLengthChange()"
                ></v-select>
                <div class="pl-3">
                  Rows
                </div>
              </div>
            </v-col>

            <v-spacer></v-spacer>

            <v-col
              sm="10"
              cols="4"
              class="d-flex justify-end"
            >
              <v-pagination
                v-model="tablePagination.current_page"
                total-visible="6"
                :length="tablePagination.total_page"
                @input="fetchReleaseNotes"
              ></v-pagination>
            </v-col>
          </v-row>
        </v-card-text>

        <!-- table -->
        <v-data-table
          :headers="tableHeaders"
          :items="releaseNotes"
          :options.sync="options"
          :items-per-page="100"
          :loading="loading"
          mobile-breakpoint="0"
          hide-default-footer
          class="release-note-table text-no-wrap d-none d-sm-block"
          @click:row="fetchReleaseNote($event.id)"
        >
          <!-- Notes -->
          <template #[`item.note`]="{item}">
            <div
              class="text-truncate py-1"
              style="width: 300px; max-height: 30px;"
              v-html="item.note"
            ></div>
          </template>
        </v-data-table>

        <!-- table view for mobile -->
        <v-data-table
          :headers="tableSmHeaders"
          :items="releaseNotes"
          :options.sync="options"
          :items-per-page="100"
          :loading="loading"
          :expanded.sync="expanded"
          single-expand
          show-expand
          mobile-breakpoint="0"
          hide-default-footer
          class="text-no-wrap d-block d-sm-none"
          @click:row="fetchReleaseNote($event.id)"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td
              class="table-expand"
              :colspan="headers.length"
            >
              <p class="mt-2">
                Note:
              </p>
              <p
                class="text-wrap"
                v-html="item.note"
              ></p>
            </td>
          </template>
        </v-data-table>

        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="4"
              sm="2"
            >
              <div class="d-flex align-center">
                <v-select
                  v-model="tablePagination.per"
                  :items="listLengthOptions"
                  single-line
                  outlined
                  dense
                  hide-details
                  @input="listLengthChange"
                ></v-select>
                <div class="pl-3">
                  Rows
                </div>
              </div>
            </v-col>

            <v-spacer></v-spacer>

            <v-col
              sm="10"
              cols="4"
              class="d-flex justify-end"
            >
              <v-pagination
                v-model="tablePagination.current_page"
                total-visible="6"
                :length="tablePagination.total_page"
                @input="fetchReleaseNotes"
              ></v-pagination>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="3"
    >
      <div class="text-h6 mb-2">
        Preview Here
      </div>
      <v-card class="px-2">
        <v-card-title class="d-flex align-center">
          <div class="text-h6 text-no-wrap">
            New Releases ✨
          </div>
        </v-card-title>
        <!-- New or Edit preview -->
        <template v-if="releaseNote.note != ''">
          <v-card-subtitle class="font-weight-semibold">
            {{ `${releaseNote.version} - ${releasedAtDateFormatted}` }}
          </v-card-subtitle>
          <div
            class="featured-note text-caption mx-5"
            style="max-height: 38vh;"
            v-html="releaseNote.note"
          ></div>
        </template>
        <!-- Featured preview -->
        <template v-else-if="featuredReleaseNote != 0">
          <v-card-subtitle class="font-weight-semibold">
            {{ `${featuredReleaseNote.version} - ${featuredDateFormatted}` }}
          </v-card-subtitle>
          <div
            class="featured-note text-caption mx-5"
            style="max-height: 38vh;"
            v-html="featuredReleaseNote.note"
          ></div>
        </template>
        <!-- Empty preview -->
        <v-card-subtitle
          v-else
          class="font-weight-semibold"
        >
          Release note will be added soon.
        </v-card-subtitle>
        <div
          v-if="featuredReleaseNote != 0"
          class="text-center py-5"
        >
          <v-dialog
            v-model="releaseNotesDialog"
            scrollable
            max-width="95vh"
          >
            <template v-slot:activator="{ on, attrs }">
              <span
                class="caption text-decoration-underline primary--text"
                v-bind="attrs"
                v-on="on"
              >
                View more releases
              </span>
            </template>
            <v-card>
              <v-card-title class="d-flex align-center">
                <div class="text-h6 text-no-wrap">
                  New Releases ✨
                </div>
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  small
                  @click="releaseNotesDialog = false"
                >
                  <v-icon size="22">
                    {{ icons.mdiClose }}
                  </v-icon>
                </v-btn>
              </v-card-title>
              <v-tabs
                v-if="$vuetify.breakpoint.mdAndUp"
                hide-slider
                vertical
              >
                <v-tab
                  v-for="item in previewReleaseNotes"
                  :key="item.id"
                  class="caption text-decoration-underline text-lowercase"
                >
                  {{ `${item.version} - ${item.released_at}` }}
                </v-tab>

                <v-tab-item
                  v-for="item in previewReleaseNotes"
                  :key="item.id"
                  :transition="false"
                >
                  <v-card
                    min-height="70vh"
                    flat
                  >
                    <v-card-text>
                      <p v-html="item.note"></p>
                    </v-card-text>
                  </v-card>
                  <div class="text-right mx-5 mb-5">
                    <v-btn
                      depressed
                      color="primary"
                      @click="releaseNotesDialog = false"
                    >
                      Ok, Got It!
                    </v-btn>
                  </div>
                </v-tab-item>
              </v-tabs>
              <template v-else>
                <v-select
                  v-model="currentReleaseNoteItem"
                  :items="previewReleaseNotes"
                  item-value="note"
                  outlined
                  dense
                  class="mx-5"
                >
                  <template
                    slot="selection"
                    slot-scope="data"
                  >
                    <div class="caption text-lowercase">
                      {{ data.item.version }} - {{ data.item.released_at }}
                    </div>
                  </template>
                  <template
                    slot="item"
                    slot-scope="data"
                  >
                    <div class="caption text-lowercase">
                      {{ data.item.version }} - {{ data.item.released_at }}
                    </div>
                  </template>
                </v-select>
                <div>
                  <v-card
                    min-height="65vh"
                    flat
                  >
                    <v-card-text>
                      <p v-html="currentReleaseNoteItem"></p>
                    </v-card-text>
                  </v-card>
                  <div class="text-right mx-5 mb-5">
                    <v-btn
                      depressed
                      color="primary"
                      @click="releaseNotesDialog = false"
                    >
                      Ok, Got It!
                    </v-btn>
                  </div>
                </div>
              </template>
            </v-card>
          </v-dialog>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiCalendar,
  mdiClose,
} from '@mdi/js'
import {
  ref,
  onMounted,
  inject,
  computed,
  watch,
} from '@vue/composition-api'
import { required } from '@core/utils/validation'
import { VueEditor } from 'vue2-editor'

export default {
  components: { VueEditor },
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const utilsService = inject('utilsService')

    // Properties
    const [dayNow, monthNow, yearNow] = new Date().toLocaleDateString('en-SG').split('/')
    const releaseNotes = ref([])
    const releaseNote = ref({
      version: 'v0.0.0',
      note: '',
      released_at: `${yearNow}-${monthNow}-${dayNow}`,
    })
    const featuredReleaseNote = ref({})
    const isReleasedAtMenuOpen = ref(false)
    const saveReleaseNoteForm = ref(null)
    const saveReleaseNoteValid = ref(false)
    const saveReleaseNoteLoading = ref(false)

    const previewReleaseNotes = ref([])
    const currentReleaseNoteItem = ref({})
    const releaseNotesDialog = ref(false)

    const isDeleteDialogOpen = ref(false)
    const deleteReleaseNoteLoading = ref(false)

    const customToolBar = [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' },
      ],
    ]

    // Validators
    const versionRules = [
      v => !!v || 'This field is required',
      v => /^[v]/.test(v) || 'Must start with v letter',
      v => /^[v]?([0-9]+)\)?[.]([0-9]{1})[.]([0-9]{1})$/.test(v) || 'Must be valid build number',
    ]

    // Table Handlers
    const loading = ref(false)
    const expanded = ref([])
    const options = ref({
      sortBy: ['version'],
      sortDesc: [true],
    })
    const listLengthOptions = ref([20, 50, 100])
    const tablePagination = ref({
      current_page: 1,
      per: 20,
      total_count: 0,
      total_page: 0,
    })
    const tableHeaders = computed(() => (
      [
        { text: 'VERSION', value: 'version' },
        { text: 'NOTES', value: 'note', sortable: false },
        { text: 'DATE', value: 'released_at', sortable: false },
      ]
    ))
    const tableSmHeaders = computed(() => (
      [
        { text: 'VERSION', value: 'version', sortable: false },
        { text: 'DATE', value: 'released_at', sortable: false },
        { text: '', value: 'data-table-expand' },
      ]
    ))

    // Computed
    const releasedAtDateFormatted = computed(() => utilsService.formatDate(releaseNote.value.released_at))
    const featuredDateFormatted = computed(() => utilsService.formatDate(featuredReleaseNote.value.released_at))

    // Methods
    const newVersionNumber = version => {
      if (!version) return 'v0.0.0'

      const [n1, n2, n3] = version.split('.')

      let newNumber1 = Number(n1.slice(1))
      let newNumber2 = Number(n2)
      let newNumber3 = Number(n3)

      if ((newNumber3 + 1) === 10) {
        newNumber3 = 0
        if ((newNumber2 + 1) === 10) {
          newNumber2 = 0
          newNumber1 += 1
        } else {
          newNumber2 += 1
        }
      } else {
        newNumber3 += 1
      }

      return `v${newNumber1}.${newNumber2}.${newNumber3}`
    }
    const fetchReleaseNotes = () => {
      loading.value = true
      store
        .dispatch('releaseNoteStore/fetchReleaseNotes', {
          page: tablePagination.value.current_page,
          per: tablePagination.value.per,
          sort_by: options.value.sortBy[0],
          sort_dir: options.value.sortDesc[0] ? 'desc' : 'asc',
        })
        .then(response => {
          const { data, pagination, featured } = response.data
          releaseNotes.value = data
          tablePagination.value = pagination
          featuredReleaseNote.value = featured
          currentReleaseNoteItem.value = featuredReleaseNote.value.note
          loading.value = false
          releaseNote.value = {
            version: newVersionNumber(featured.version),
            note: '',
            released_at: `${yearNow}-${monthNow}-${dayNow}`,
          }
        })
        .catch(error => {
          loading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching release notes. Please refresh!')
        })
    }
    const fetchReleaseNote = id => {
      store
        .dispatch('releaseNoteStore/fetchReleaseNote', { id })
        .then(response => {
          releaseNote.value = response.data.data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching release note. Please refresh!')
        })
    }
    const fetchAllReleaseNotes = () => {
      store
        .dispatch('releaseNoteStore/fetchReleaseNotes', { sort_by: 'version', sort_dir: 'desc', no_pagination: true })
        .then(response => {
          previewReleaseNotes.value = response.data.data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching release notes. Please refresh!')
        })
    }
    const saveReleaseNote = () => {
      saveReleaseNoteLoading.value = true
      store
        .dispatch(`releaseNoteStore/${releaseNote.value.id ? 'updateReleaseNote' : 'createReleaseNote'}`, releaseNote.value)
        .then(response => {
          snackbarService.success(response.data.message)
          saveReleaseNoteLoading.value = false
          saveReleaseNoteForm.value = null
          fetchReleaseNotes()
          fetchAllReleaseNotes()
        })
        .catch(error => {
          saveReleaseNoteLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while saving release note. Please refresh!')
        })
    }
    const deleteReleaseNote = () => {
      deleteReleaseNoteLoading.value = true
      store
        .dispatch('releaseNoteStore/deleteReleaseNote', { id: releaseNote.value.id })
        .then(response => {
          snackbarService.success(response.data.message)
          deleteReleaseNoteLoading.value = false
          isDeleteDialogOpen.value = false
          saveReleaseNoteForm.value = null
          fetchReleaseNotes()
          fetchAllReleaseNotes()
        })
        .catch(error => {
          deleteReleaseNoteLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while deleting release note. Please refresh!')
        })
    }
    const listLengthChange = () => {
      tablePagination.value.current_page = 1
      fetchReleaseNotes()
    }

    // Watch
    let timer = null
    watch([options], () => {
      if (timer) {
        clearTimeout(timer)
        timer = null
      }
      timer = setTimeout(() => {
        fetchReleaseNotes()
      }, 200)
    })

    // Mounted
    onMounted(async () => fetchAllReleaseNotes())

    return {
      // Computed
      releasedAtDateFormatted,
      featuredDateFormatted,

      // Properties
      releaseNotes,
      releaseNote,
      featuredReleaseNote,

      previewReleaseNotes,
      currentReleaseNoteItem,
      releaseNotesDialog,

      dayNow,
      monthNow,
      yearNow,

      isReleasedAtMenuOpen,
      saveReleaseNoteForm,
      saveReleaseNoteValid,
      saveReleaseNoteLoading,

      isDeleteDialogOpen,
      deleteReleaseNoteLoading,
      customToolBar,

      // Table Handlers
      loading,
      expanded,
      options,
      listLengthOptions,
      tablePagination,
      tableHeaders,
      tableSmHeaders,

      // Validators
      validators: {
        required,
      },
      versionRules,

      // Icons
      icons: {
        mdiCalendar,
        mdiClose,
      },

      // Methods
      newVersionNumber,
      fetchReleaseNotes,
      fetchReleaseNote,
      fetchAllReleaseNotes,
      saveReleaseNote,
      deleteReleaseNote,
      listLengthChange,
    }
  },
}
</script>

<style>
.release-note-table tbody tr {
  cursor: pointer;
}
.featured-note {
  overflow: hidden;
}
</style>
