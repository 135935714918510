import { axios, baseURL } from '@axios'
import encryptionService from '@/services/encryption.service'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCustomers(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/admin/customers', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCustomer(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/admin/customers/${params.id}`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCustomer(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/admin/customers/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCustomer(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/admin/customers/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchStat(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/admin/customers/${params.id}/count`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBilling(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/admin/billings/${params.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateBilling(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/admin/billings/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSubscriptions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/admin/subscriptions/${params.id}`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createSubscription(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/admin/subscriptions', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteSubscription(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/admin/subscriptions/${params.id}`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPayments(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/admin/payments', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    downloadPayment(ctx, params) {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/api/v1/admin/payments/${params.id}.pdf`, {
          params,
          method: 'GET',
          headers: JSON.parse(encryptionService.decrypt(localStorage.getItem('authHeaders'))),
        })
          .then(response => resolve(response.blob()))
          .catch(error => reject(error))
      })
    },
  },
}
