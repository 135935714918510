import store from '@/store'

export default {
  success(content) {
    store.dispatch('showSnackbar', { content, status: 'success' })
  },
  error(content) {
    store.dispatch('showSnackbar', { content, status: 'error' })
  },
  warning(content) {
    store.dispatch('showSnackbar', { content, status: 'warning' })
  },
  info(content) {
    store.dispatch('showSnackbar', { content, status: 'info' })
  },
}
