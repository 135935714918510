import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'

import customerStoreModule from './customer/customerStoreModule'
import subscriptionTypeStoreModule from './module/subscriptionTypeStoreModule'
import releaseNoteStoreModule from './release_note/releaseNoteStoreModule'
import tutorialStoreModule from './tutorial/tutorialStoreModule'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    snackbar: {
      content: '',
      status: '',
    },
  },
  getters: {
    isSnackbarVisible(state) {
      return state.snackbar.isSnackbarVisible
    },
  },
  mutations: {
    SHOW_SNACKBAR(state, value) {
      state.snackbar.content = value.content
      state.snackbar.status = value.status
    },
    SET_SETTINGS(state, value) {
      state.settings = value
    },
  },
  actions: {
    showSnackbar({ commit }, value) {
      commit('SHOW_SNACKBAR', value)
    },
  },
  modules: {
    appConfig: appConfigStoreModule,
    app,
    customerStore: customerStoreModule,
    subscriptionTypeStore: subscriptionTypeStoreModule,
    releaseNoteStore: releaseNoteStoreModule,
    tutorialStore: tutorialStoreModule,
  },
})
