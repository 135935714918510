<template>
  <div>
    <v-row>
      <v-col
        v-for="(item, key) in stat"
        :key="key"
        cols="12"
        md="4"
      >
        <statistics-card-vertical
          :statistics="String(item.total)"
          :change="String(item.today)"
          :stat-title="`Total ${capitalize(key)}`"
        ></statistics-card-vertical>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  ref,
  inject,
  onMounted,
} from '@vue/composition-api'
import { useRouter } from '@core/utils'
import StatisticsCardVertical from '@/@core/components/statistics-card/StatisticsCardVertical.vue'

export default {
  components: {
    StatisticsCardVertical,
  },
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const { route } = useRouter()
    const customerId = route.value.params.id

    // Properties
    const stat = ref({})

    // Methods
    const fetchStat = id => {
      store
        .dispatch('customerStore/fetchStat', { id })
        .then(response => {
          stat.value = response.data.data
        })
        .catch(error => snackbarService.error(error.response.data.message || 'Something went wrong. Please refresh!'))
    }
    const capitalize = key => {
      let capitalizedText = ''
      switch (key) {
        case 'category':
          capitalizedText = 'Categories'
          break
        case 'item':
          capitalizedText = 'Products & Services'
          break
        default:
          capitalizedText = `${key.charAt(0).toUpperCase()}${key.slice(1)}s`
      }

      return capitalizedText
    }

    // Mounted
    onMounted(() => {
      if (customerId) fetchStat(customerId)
    })

    return {
      // Properties
      stat,

      // Methods
      capitalize,
    }
  },
}
</script>
