<template>
  <component
    :is="resolveLayoutVariant"
    :class="`skin-variant--${appSkinVariant}`"
  >
    <snackbar></snackbar>
    <transition
      :name="appRouteTransition"
      mode="out-in"
      appear
    >
      <router-view></router-view>
    </transition>
  </component>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed, provide } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
import { useRouter } from '@core/utils'
import { useLayout } from '@core/layouts/composable/useLayout'

// Layouts
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'
import Snackbar from '@/components/Snackbar.vue'

// Dynamic vh
import useDynamicVh from '@core/utils/useDynamicVh'

// Other libraries
import store from '@/store'
import snackbarService from '@/services/snackbar.service'
import utilsService from '@/services/utils.service'
import { can } from '@core/libs/acl/utils'
import { useUtils } from '@core/libs/i18n'

export default {
  components: {
    LayoutContentVerticalNav,
    LayoutBlank,
    Snackbar,
  },
  setup() {
    provide('store', store)
    provide('can', can)
    const { t } = useUtils()
    provide('t', t)
    provide('snackbarService', snackbarService)
    provide('utilsService', utilsService)

    const { route } = useRouter()
    const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()

    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()

    const resolveLayoutVariant = computed(() => {
      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'content') return `layout-content-${appContentLayoutNav.value}-nav`

      return null
    })

    useDynamicVh()

    return {
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
    }
  },
}
</script>

<style>
.v-data-table th {
  padding: 15px !important;
}
.table-expand p {
  font-size: 11px !important;
  margin-bottom: 10px !important;
}
@media (max-width: 599px) {
  .v-pagination__item,
  .v-pagination__more {
    display: none !important;
  }
  .v-pagination__item--active {
    display: block !important;
  }
}
</style>
