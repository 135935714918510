<template>
  <div class="mb-7">
    <v-card class="mb-5">
      <v-card-title>
        Details
      </v-card-title>
      <v-card-text>
        <v-simple-table dense>
          <template v-slot:default>
            <tbody>
              <tr
                v-for="(item, key) in customerDetails"
                :key="key"
              >
                <td class="text-right font-weight-bold detailLabel">
                  {{ capitalizeDetails(key) }}:
                </td>
                <td>
                  {{ item }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  computed,
} from '@vue/composition-api'

export default {
  props: {
    customer: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    // Computed
    const filterDetails = customer => {
      if (!customer) return null

      return {
        name: customer.name,
        display_name: customer.display_name,
        email: customer.email,
        roc_number: customer.roc_number,
        contact_number: customer.contact,
        website: customer.website,
        description: customer.description,
        address: customer.address,
        joined_at: customer.created_at,
      }
    }
    const customerDetails = computed(() => filterDetails(props.customer))

    // Methods
    const capitalizeDetails = key => {
      if (!key) return null

      const splitOutput = key.split('_')
      if (key === 'roc_number') {
        splitOutput[0] = splitOutput[0].toUpperCase()
      } else {
        splitOutput[0] = splitOutput[0].charAt(0).toUpperCase() + splitOutput[0].slice(1)
      }
      if (splitOutput[1]) {
        splitOutput[1] = splitOutput[1].charAt(0).toUpperCase() + splitOutput[1].slice(1)
      }

      return splitOutput.join(' ')
    }

    return {
      // Computed
      customerDetails,

      // Methods
      capitalizeDetails,
    }
  },
}
</script>

<style lang="scss" scoped>
  tbody {
    tr:hover {
      background-color: transparent !important;
    }
    td {
      border-bottom: none !important;
    }
    .detailLabel {
      width: 200px;
    }
  }
</style>
