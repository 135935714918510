<template>
  <div>
    <v-card id="customer-list">
      <v-card-text class="d-flex align-center pb-5 justify-end">
        <v-row justify="end">
          <v-col
            cols="12"
            sm="3"
            lg="2"
          >
            <!-- filter billing status -->
            <v-select
              v-model="statusFilter"
              :items="statusOptions"
              single-line
              outlined
              dense
              hide-details
              clearable
              placeholder="Billing Status"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            sm="3"
            lg="2"
            class="pr-sm-0"
          >
            <!-- filter billing payment period -->
            <v-select
              v-model="periodFilter"
              :items="periodOptions"
              single-line
              outlined
              dense
              hide-details
              clearable
              placeholder="Billing Period"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            sm="4"
            lg="3"
            class="d-flex pl-sm-0"
          >
            <!-- search customer -->
            <v-tooltip
              :left="$vuetify.breakpoint.smAndUp"
              :top="$vuetify.breakpoint.xsOnly"
              :nudge-bottom="$vuetify.breakpoint.smAndUp ? 20 : 0"
              :nudge-top="$vuetify.breakpoint.xsOnly ? 5 : 0"
              :nudge-right="$vuetify.breakpoint.xsOnly ? 100 : 0"
              max-width="250"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  size="30"
                  class="mx-3 my-auto hover-pointer d-none d-sm-block"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="25">
                    {{ icons.mdiAlertCircleOutline }}
                  </v-icon>
                </v-avatar>
              </template>
              <h3 class="my-2">
                How to use the search bar
              </h3>
              <p class="mb-1">
                You can search using:
              </p>
              <ul class="mb-2">
                <li>
                  Customer name
                </li>
              </ul>
            </v-tooltip>
            <v-text-field
              v-model="searchText"
              :append-icon="icons.mdiMagnify"
              single-line
              dense
              outlined
              hide-details
              placeholder="Search Customer"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="pt-5">
        <v-row>
          <v-col
            cols="4"
            sm="2"
          >
            <div class="d-flex align-center">
              <v-select
                v-model="tablePagination.per"
                :items="listLengthOptions"
                single-line
                outlined
                dense
                hide-details
                class="customer-list-row-selection"
                @input="listLengthChange()"
              ></v-select>
              <div class="pl-3">
                Rows
              </div>
            </div>
          </v-col>

          <v-spacer></v-spacer>

          <v-col
            sm="10"
            cols="4"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="tablePagination.current_page"
              total-visible="6"
              :length="tablePagination.total_page"
              @input="fetchCustomers"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- table -->
      <v-data-table
        :headers="tableHeaders"
        :items="customers"
        :options.sync="options"
        :items-per-page="100"
        :loading="loading"
        mobile-breakpoint="0"
        hide-default-footer
        class="customer-table text-no-wrap fixed-action d-none d-sm-block"
        @click:row="customerDetails"
      >
        <!-- Status -->
        <template #[`item.billing_status`]="{item}">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                size="30"
                :color="billingStatus(item.billing_status).variant"
                :class="`v-avatar-light-bg ${billingStatus(item.billing_status).variant}--text`"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon
                  size="18"
                  :color="billingStatus(item.billing_status).variant"
                >
                  {{ billingStatus(item.billing_status).icon }}
                </v-icon>
              </v-avatar>
            </template>
            <span>{{ item.billing_status }}</span>
          </v-tooltip>
        </template>

        <!-- Total -->
        <template #[`item.created_at`]="{item}">
          <div>
            {{ item.joined_at }}
          </div>
        </template>

        <!-- Total -->
        <template #[`item.total`]="{item}">
          <div>
            ${{ item.total }}
          </div>
        </template>

        <!-- Email -->
        <template #[`item.email`]="{item}">
          <div>
            {{ item.email }}
          </div>
        </template>

        <!-- Contact -->
        <template #[`item.contact`]="{item}">
          <div>
            {{ item.contact }}
          </div>
        </template>

        <!-- ROC Number -->
        <template #[`item.roc_number`]="{item}">
          <div>
            {{ item.roc_number }}
          </div>
        </template>

        <!-- Actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="me-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="customer = item; isDeleteDialogOpen = true">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>

      <!-- table view for mobile -->
      <v-data-table
        :headers="tableSmHeaders"
        :items="customers"
        :options.sync="options"
        :items-per-page="100"
        :loading="loading"
        :expanded.sync="expanded"
        single-expand
        show-expand
        mobile-breakpoint="0"
        hide-default-footer
        class="customer-table text-no-wrap d-block d-sm-none"
        @click:row="customerDetails"
      >
        <!-- Status -->
        <template #[`item.billing_status`]="{item}">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                size="30"
                :color="billingStatus(item.billing_status).variant"
                :class="`v-avatar-light-bg ${billingStatus(item.billing_status).variant}--text`"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon
                  size="18"
                  :color="billingStatus(item.billing_status).variant"
                >
                  {{ billingStatus(item.billing_status).icon }}
                </v-icon>
              </v-avatar>
            </template>
            <span>{{ item.billing_status }}</span>
          </v-tooltip>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td
            class="table-expand"
            :colspan="headers.length"
          >
            <p class="mt-2">
              Joined At: {{ item.joined_at }}
            </p>
            <p>Payment Period: {{ item.payment_period }}</p>
            <p>Payment Total: ${{ item.total }}</p>
            <p>Email: {{ item.email }}</p>
            <p>Contact No: {{ item.contact }}</p>
            <div class="d-flex">
              <p>ROC No: {{ item.roc_number }}</p>
              <v-spacer></v-spacer>
              <v-menu
                bottom
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="me-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="customer = item; isDeleteDialogOpen = true">
                    <v-list-item-title>
                      <v-icon
                        size="16"
                        class="me-2"
                      >
                        {{ icons.mdiDeleteOutline }}
                      </v-icon>
                      <span class="caption">Delete</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </td>
        </template>
      </v-data-table>

      <!-- Delete Dialog -->
      <v-dialog
        v-model="isDeleteDialogOpen"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title class="d-flex align-center mv-4">
            Delete {{ customer.name }}?
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              @click="isDeleteDialogOpen = false; customer = {}"
            >
              <v-icon size="22">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            This customer and all the related records will be removed from this list. It will no longer appear in searches or available for use.
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="error"
              block
              :loading="deleteCustomerLoading"
              :disabled="deleteCustomerLoading"
              class="mt-3"
              @click="deleteCustomer(customer.id)"
            >
              Yes, remove
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card-text class="pt-5">
        <v-row>
          <v-col
            cols="4"
            sm="2"
          >
            <div class="d-flex align-center">
              <v-select
                v-model="tablePagination.per"
                :items="listLengthOptions"
                single-line
                outlined
                dense
                hide-details
                class="customer-list-row-selection"
                @input="listLengthChange()"
              ></v-select>
              <div class="pl-3">
                Rows
              </div>
            </div>
          </v-col>

          <v-spacer></v-spacer>

          <v-col
            sm="10"
            cols="4"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="tablePagination.current_page"
              total-visible="6"
              :length="tablePagination.total_page"
              @input="fetchCustomers"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  mdiAlertCircleOutline,
  mdiMagnify,
  mdiCheckCircleOutline,
  mdiChartTimelineVariant,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiClose,
} from '@mdi/js'
import {
  ref,
  inject,
  computed,
  watch,
} from '@vue/composition-api'
import { useRouter } from '@core/utils'

export default {
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const { router } = useRouter()

    // Properties
    const customers = ref([])
    const customer = ref({})

    const searchText = ref('')
    const statusFilter = ref(null)
    const statusOptions = ref(['Active', 'Expired', 'Trial'])
    const periodFilter = ref(null)
    const periodOptions = ref(['Annually', 'Monthly'])

    const isDeleteDialogOpen = ref(false)
    const deleteCustomerLoading = ref(false)

    // Table Handlers
    const loading = ref(false)
    const expanded = ref([])
    const options = ref({
      sortBy: ['created_at'],
      sortDesc: [true],
    })
    const listLengthOptions = ref([20, 50, 100])
    const tablePagination = ref({
      current_page: 1,
      per: 20,
      total_count: 0,
      total_page: 0,
    })
    const tableHeaders = computed(() => (
      [
        { text: 'NAME', value: 'name' },
        { text: 'JOINED AT', value: 'created_at' },
        {
          text: 'STATUS',
          align: 'center',
          value: 'billing_status',
          sortable: false,
        },
        { text: 'PAYMENT PERIOD', value: 'payment_period', sortable: false },
        { text: 'PAYMENT TOTAL', value: 'total' },
        { text: 'EMAIL', value: 'email', sortable: false },
        { text: 'CONTACT NO.', value: 'contact', sortable: false },
        { text: 'ROC NO.', value: 'roc_number', sortable: false },
        {
          text: 'ACTIONS',
          value: 'actions',
          align: 'right',
          sortable: false,
        },
      ]
    ))
    const tableSmHeaders = computed(() => (
      [
        { text: 'NAME', value: 'name' },
        {
          text: 'STATUS',
          align: 'center',
          value: 'billing_status',
          sortable: false,
        },
        { text: '', value: 'data-table-expand' },
      ]
    ))

    // Methods
    const fetchCustomers = () => {
      loading.value = true
      store
        .dispatch('customerStore/fetchCustomers', {
          page: tablePagination.value.current_page,
          per: tablePagination.value.per,
          sort_by: options.value.sortBy[0],
          sort_dir: options.value.sortDesc[0] ? 'desc' : 'asc',
          search_text: searchText.value,
          billing_status: statusFilter.value,
          billing_period: periodFilter.value,
        })
        .then(response => {
          const { data, pagination } = response.data
          customers.value = data
          tablePagination.value = pagination
          loading.value = false
        })
        .catch(error => {
          loading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching customers. Please refresh!')
        })
    }
    const deleteCustomer = customerId => {
      deleteCustomerLoading.value = true
      store
        .dispatch('customerStore/deleteCustomer', { id: customerId })
        .then(response => {
          snackbarService.success(response.data.message)
          fetchCustomers()
          isDeleteDialogOpen.value = false
          deleteCustomerLoading.value = false
          customer.value = {}
        })
        .catch(error => {
          deleteCustomerLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while deleting customer. Please refresh!')
        })
    }
    const listLengthChange = () => {
      tablePagination.value.current_page = 1
      fetchCustomers()
    }
    const billingStatus = status => {
      let variantAndIcon = {}
      switch (status) {
        case 'Active':
          variantAndIcon = { variant: 'success', icon: mdiCheckCircleOutline }
          break
        case 'Trial':
          variantAndIcon = { variant: 'warning', icon: mdiChartTimelineVariant }
          break
        case 'Expired':
          variantAndIcon = { variant: 'error', icon: mdiAlertCircleOutline }
          break
        default:
          variantAndIcon = { variant: 'secondary', icon: mdiClose }
      }

      return variantAndIcon
    }
    const customerDetails = value => {
      router.push(`/customer/${value.id}`)
    }

    // Watch
    let timer = null
    watch([searchText, statusFilter, periodFilter, options], () => {
      if (timer) {
        clearTimeout(timer)
        timer = null
      }
      timer = setTimeout(() => {
        fetchCustomers()
      }, 200)
    })

    return {
      // Properties
      customers,
      customer,

      searchText,
      statusFilter,
      statusOptions,
      periodFilter,
      periodOptions,

      isDeleteDialogOpen,
      deleteCustomerLoading,

      // Table Handlers
      loading,
      expanded,
      options,
      listLengthOptions,
      tablePagination,
      tableHeaders,
      tableSmHeaders,

      // Icons
      icons: {
        mdiAlertCircleOutline,
        mdiMagnify,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiClose,
      },

      // Methods
      fetchCustomers,
      deleteCustomer,
      listLengthChange,
      billingStatus,
      customerDetails,
    }
  },
}
</script>

<style>
.customer-table tbody tr {
  cursor: pointer;
}
</style>
