<template>
  <v-row>
    <v-col
      v-if="$vuetify.breakpoint.smAndDown"
      class="tutorial-group-items"
    >
      <v-select
        v-if="tutorialGroups.length"
        v-model="currentItem"
        :items="tutorialGroups"
        item-text="title"
        outlined
        dense
        return-object
      ></v-select>
      <v-card v-if="currentItem">
        <v-card-title class="py-3">
          <v-avatar
            size="40"
            color="white"
            class="mr-4 hover-pointer"
            @click="fetchTutorialGroup(currentItem.id); isSaveDialogOpen = true"
          >
            <v-icon
              size="20"
              color="primary"
            >
              {{ icons[currentItem.icon.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())] || icons['mdiPencil'] }}
            </v-icon>
          </v-avatar>
          {{ currentItem.title }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            :to="{ name: 'tutorial-edit', params: { id: currentItem.id } }"
          >
            <v-icon
              size="20"
              color="white"
            >
              {{ icons.mdiPlus }}
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text
          v-if="currentItem.tutorials.length > 0"
          class="py-4"
        >
          <div
            v-for="tutorial in currentItem.tutorials"
            :key="tutorial.id"
            class="px-0 d-flex"
          >
            <v-icon
              size="14"
              class="mt-1 mx-2"
            >
              {{ icons.mdiCircleOutline }}
            </v-icon>
            <p class="mb-3 font-weight-medium">
              {{ tutorial.title }}
            </p>
          </div>
        </v-card-text>
        <v-card-text
          v-else
          class="text-center"
        >
          <h3 class="mt-7 mb-1">
            No tutorials yet!
          </h3>
          <p class="mx-8 caption">
            Click on the + icon on the top right to add a new page into this tutorial group
          </p>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      v-for="item in tutorialGroups"
      v-else
      :key="item.id"
      cols="12"
      sm="6"
      md="4"
      class="tutorial-group-items"
    >
      <v-card>
        <v-card-title class="py-3">
          <v-avatar
            size="40"
            color="white"
            class="mr-4 hover-pointer"
            @click="fetchTutorialGroup(item.id); isSaveDialogOpen = true"
          >
            <v-icon
              size="20"
              color="primary"
            >
              {{ icons[item.icon.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())] || icons['mdiPencil'] }}
            </v-icon>
          </v-avatar>
          {{ item.title }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            :to="{ name: 'tutorial-edit', params: { id: item.id } }"
          >
            <v-icon
              size="20"
              color="white"
            >
              {{ icons.mdiPlus }}
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text
          v-if="item.tutorials.length > 0"
          class="py-4"
        >
          <div
            v-for="tutorial in item.tutorials"
            :key="tutorial.id"
            class="px-0 d-flex"
          >
            <v-icon
              size="14"
              class="mt-1 mx-2"
            >
              {{ icons.mdiCircleOutline }}
            </v-icon>
            <p class="mb-3 font-weight-medium">
              {{ tutorial.title }}
            </p>
          </div>
        </v-card-text>
        <v-card-text
          v-else
          class="text-center"
        >
          <h3 class="mt-7 mb-1">
            No tutorials yet!
          </h3>
          <p class="mx-8 caption">
            Click on the + icon on the top right to add a new page into this tutorial group
          </p>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="4"
      class="tutorial-group-items"
    >
      <v-card>
        <v-card-title
          class="py-3"
          style="background-color: #909090 !important;"
        >
          <v-avatar
            size="40"
            color="white"
            class="mr-4"
          >
            <v-icon
              size="20"
              color="#909090"
            >
              {{ icons.mdiPencil }}
            </v-icon>
          </v-avatar>
          Add New Group
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon
              size="20"
              color="white"
              @click="isSaveDialogOpen = true"
            >
              {{ icons.mdiPlus }}
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text-center">
          <p class="mx-7 mt-7 caption">
            Click on the + icon on the top right to create a new tutorial group
          </p>
        </v-card-text>
      </v-card>
    </v-col>

    <!-- Save Dialog -->
    <v-dialog
      v-model="isSaveDialogOpen"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="d-flex align-center mv-4 mb-4">
          {{ tutorialGroup.id ? 'Edit' : 'New' }} Tutorial Group
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isSaveDialogOpen = false; saveForm.reset(); tutorialGroup = {}"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form
            ref="saveForm"
            v-model="saveValid"
          >
            <v-text-field
              v-model="tutorialGroup.title"
              outlined
              dense
              label="Title"
              :rules="[validators.required]"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>
            <v-text-field
              v-model="tutorialGroup.icon"
              outlined
              dense
              label="Icon"
              hint="Find valid icons"
              persistent-hint
            >
              <template v-slot:message="{ message }">
                <a
                  href="https://materialdesignicons.com"
                  target="_blank"
                  class="text-decoration-none"
                >
                  <span>{{ message }}</span>
                </a>
              </template>
            </v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="primary"
            :loading="saveTutorialGroupLoading"
            :disabled="saveTutorialGroupLoading || !saveValid"
            class="flex-grow-1"
            @click="saveTutorialGroup"
          >
            Save
          </v-btn>
          <v-btn
            v-if="tutorialGroup.id"
            color="error"
            :loading="saveTutorialGroupLoading"
            :disabled="saveTutorialGroupLoading || !saveValid"
            class="flex-grow-1"
            @click="isDeleteDialogOpen = true"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Dialog -->
    <v-dialog
      v-model="isDeleteDialogOpen"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="d-flex align-center mv-4">
          Delete {{ tutorialGroup.title }}?
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isDeleteDialogOpen = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          This tutorial group will be removed from this list and the tutorial pages will no longer available for use.
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="error"
            block
            :loading="deleteTutorialGroupLoading"
            :disabled="deleteTutorialGroupLoading"
            class="mt-3"
            @click="deleteTutorialGroup(tutorialGroup.id)"
          >
            Yes, remove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  mdiPlus,
  mdiPencil,
  mdiCircleOutline,
  mdiClose,
  mdiNotebookEditOutline,
  mdiBookOpenVariant,
  mdiPointOfSale,
  mdiDomain,
  mdiCreditCardOutline,
} from '@mdi/js'
import {
  onMounted,
  ref,
  inject,
} from '@vue/composition-api'
import { required } from '@core/utils/validation'

export default {
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')

    // Properties
    const tutorialGroups = ref([])
    const tutorialGroup = ref({})
    const currentItem = ref(null)

    const saveForm = ref(null)
    const saveValid = ref(false)
    const isSaveDialogOpen = ref(false)
    const saveTutorialGroupLoading = ref(false)

    const isDeleteDialogOpen = ref(false)
    const deleteTutorialGroupLoading = ref(false)

    // Methods
    const defaultSelectItem = index => {
      if (tutorialGroups.value.length > 0) {
        currentItem.value = {
          id: tutorialGroups.value[index].id,
          title: tutorialGroups.value[index].title,
          icon: tutorialGroups.value[index].icon,
          tutorials: tutorialGroups.value[index].tutorials,
        }
      }
    }
    const fetchTutorialGroups = (index = 0) => {
      store
        .dispatch('tutorialStore/fetchTutorialGroups')
        .then(response => {
          tutorialGroups.value = response.data.data
          defaultSelectItem(index)
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching tutorial groups. Please refresh!')
        })
    }
    const fetchTutorialGroup = id => {
      store
        .dispatch('tutorialStore/fetchTutorialGroup', { id })
        .then(response => {
          tutorialGroup.value = response.data.data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching tutorial group. Please refresh!')
        })
    }
    const saveTutorialGroup = () => {
      saveTutorialGroupLoading.value = true
      store
        .dispatch(`tutorialStore/${tutorialGroup.value.id ? 'updateTutorialGroup' : 'createTutorialGroup'}`, tutorialGroup.value)
        .then(response => {
          snackbarService.success(response.data.message)

          const index = tutorialGroup.value.id ? tutorialGroups.value.findIndex(x => x.id === tutorialGroup.value.id) : tutorialGroups.value.length
          fetchTutorialGroups(index)
          isSaveDialogOpen.value = false
          saveTutorialGroupLoading.value = false
          saveForm.value.reset()
          tutorialGroup.value = {}
        })
        .catch(error => {
          saveTutorialGroupLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while adding tutorial group. Please refresh!')
        })
    }
    const deleteTutorialGroup = id => {
      deleteTutorialGroupLoading.value = true
      store
        .dispatch('tutorialStore/deleteTutorialGroup', { id })
        .then(response => {
          snackbarService.success(response.data.message)
          fetchTutorialGroups()
          isDeleteDialogOpen.value = false
          isSaveDialogOpen.value = false
          deleteTutorialGroupLoading.value = false
          tutorialGroup.value = {}
        })
        .catch(error => {
          deleteTutorialGroupLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while deleting tutorial group. Please refresh!')
        })
    }

    // Mounted
    onMounted(() => {
      fetchTutorialGroups()
    })

    return {
      // Properties
      tutorialGroups,
      tutorialGroup,
      currentItem,

      saveForm,
      saveValid,
      isSaveDialogOpen,
      saveTutorialGroupLoading,

      isDeleteDialogOpen,
      deleteTutorialGroupLoading,

      // Validators
      validators: {
        required,
      },

      // Icons
      icons: {
        mdiPlus,
        mdiPencil,
        mdiCircleOutline,
        mdiClose,
        mdiNotebookEditOutline,
        mdiBookOpenVariant,
        mdiPointOfSale,
        mdiDomain,
        mdiCreditCardOutline,
      },

      // Methods
      saveTutorialGroup,
      fetchTutorialGroup,
      deleteTutorialGroup,
    }
  },
}
</script>

<style lang="scss">
.tutorial-group-items {
  .theme--light.v-sheet .v-card__title {
    color: white !important;
  }

  .v-card {
    display: flex !important;
    flex-direction: column;
    max-height: calc(100vh - 250px) !important;

    .v-card__title {
      background-color: #019267 !important;
      font-size: 18px !important;
    }

    .v-card__text {
      flex-grow: 1;
      overflow: auto;
    }
  }

  .v-list-item__content > * {
    line-height: 1.5;
  }
}
</style>
