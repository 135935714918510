import { axios } from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchReleaseNotes(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/admin/release_notes', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchReleaseNote(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/admin/release_notes/${params.id}`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createReleaseNote(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/admin/release_notes', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateReleaseNote(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/admin/release_notes/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteReleaseNote(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/admin/release_notes/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
